import React, { Component } from 'react'
import { format_item_state_name } from '../Utilities'

export default class InventoryItems extends Component {
  render() {
    return (
      <div>
        {
          this.props.inventory && this.props.inventory.map((item, i) => {
            return (
              <div key={i}>
                {item.count} x {format_item_state_name(item)}
              </div>
            )
          })
        }
      </div>
    )
  }
}
