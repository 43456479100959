import React, { Component } from 'react'

import { client } from '../routes'
import { QUERY_ARMOUR_LIST, MUTATION_UPDATE_ARMOUR, MUTATION_INSERT_ARMOUR } from './queries/adminQueries.js'

export default class AdminArmour extends Component {
  constructor() {
    super()

    this.state = {
      armours: [],
      edit_armour: { name: '', description: '', cost: '', max_defence: '', min_defence: '', efficiency: ''},
      default_edit_armour: { name: '', description: '', cost: '', max_defence: '', min_defence: '',  efficiency: ''},
      add_armour: {},
      message: '',
      message_state: 'success'
    }

    this.query_armours = this.query_armours.bind(this)
    this.insert_armour = this.insert_armour.bind(this)
    this.save_armour = this.save_armour.bind(this)
    this.save_armour_click = this.save_armour_click.bind(this)
    this.display_message = this.display_message.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  display_message(message, message_state) {
    this.setState({
      message: message,
      message_state: message_state === 1 ? 'success' : 'error'
    })
  }

  save_armour_click(e) {
    e.preventDefault()
    if(this.state.edit_armour.id) {
      this.save_armour()
    } else {
      this.insert_armour()
    }
  }

  async query_armours() {
    client.query({
      query: QUERY_ARMOUR_LIST,
      fetchPolicy: 'network-only'
    }).then((response) => {
      let armours = response.data.armour
      if(armours) {
        this.setState({
          armours: armours
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  async insert_armour() {
    if(!this.state.add_armour) {
      this.setState({ message: "No armour selected to edit"})
      return
    }

    client.mutate({
      mutation: MUTATION_INSERT_ARMOUR,
      variables: { obj: [this.state.edit_armour] }
    }).then(() => {
      this.query_armours()
    }).catch((e) => {
      console.log(e)
    })
  }

  async save_armour() {
    if(!this.state.edit_armour.id) {
      this.setState({ message: "No armour selected to edit"})
      return
    }
    let { id, ...set } = this.state.edit_armour

    client.mutate({
      mutation: MUTATION_UPDATE_ARMOUR,
      variables: { id: id, set: set }
    }).then(() => {
      this.query_armours()
    }).catch((e) => {
      console.log(e)
    })
  }

  componentDidMount() {
    this.query_armours()
  }

  render() {
    return (
      <div>
        <div className={`admin-display-${this.state.message_state}-message`}>{this.state.message}</div>
        <div className="admin-edit-armour">
          <div className="admin-edit-armour-button-wrapper">
            {
              this.state.armours && this.state.armours.length > 0 && (
                <form className="edit-armour-form" onSubmit={this.save_armour_click}>
                  <table className="admin-edit-armour-table">
                    <thead>
                      <tr>
                        <th onClick={() => this.setState({ edit_armour: this.state.default_edit_armour})}>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>Min Def</th>
                        <th>Max Def</th>
                        <th>efficiency</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>
                            {this.state.edit_armour.id}
                          </td>
                          <td>
                            <input autoFocus className="input-armour-text-input" type="text" value={this.state.edit_armour.name} placeholder="Name" name="aa_name" onChange={(e) => this.setState({ edit_armour: { ...this.state.edit_armour, name: e.target.value }})} required />
                          </td>
                          <td>
                            <input className="input-armour-text-input" type="text" value={this.state.edit_armour.description} placeholder="Description" description="aa_description" onChange={(e) => this.setState({ edit_armour: { ...this.state.edit_armour, description: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_armour.cost} placeholder="Cost" name="aa_cost" onChange={(e) => this.setState({ edit_armour: { ...this.state.edit_armour, cost: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_armour.min_defence} placeholder="Minimum Defence" name="aa_min_defence" onChange={(e) => this.setState({ edit_armour: { ...this.state.edit_armour, min_defence: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_armour.max_defence} placeholder="Maximum Defence" name="aa_max_defence" onChange={(e) => this.setState({ edit_armour: { ...this.state.edit_armour, max_defence: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_armour.efficiency} placeholder="Efficiency" name="aa_efficiency" onChange={(e) => this.setState({ edit_armour: { ...this.state.edit_armour, efficiency: e.target.value }})} required />
                          </td>
                          <td>
                            <button className="admin-edit-armour-submit game-button" type="submit">Save</button>
                          </td>
                      </tr>
                      {
                        this.state.armours.map((armour) => {
                          return (
                            <tr key={armour.id}>
                              <td>{armour.id}</td>
                              <td>{armour.name}</td>
                              <td>{armour.description}</td>
                              <td>{armour.cost}</td>
                              <td>{armour.min_defence}</td>
                              <td>{armour.max_defence}</td>
                              <td>{armour.efficiency}</td>
                              <td>
                                <button className="game-button" type="button" onClick={() => this.setState({ edit_armour: armour })}>Edit</button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </form>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
