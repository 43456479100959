import React, { Component } from 'react'

import history from '../history'
import './Back.css'

export default class Back extends Component {
  constructor(props) {
    super(props)

    this.historyPop = this.historyPop.bind(this)
  }
  historyPop() {
    if(this.props.click_behaviour) {
      this.props.click_behaviour()
    } else {
      history.goBack()
    }
  }

  render() {
    return (
      <div className="back-button-wrapper">
        [<button className="back-button link-button" onClick={this.historyPop}>Back</button>]
      </div>
    )
  }
}
