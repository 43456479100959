import React, { Component } from 'react'

import { client } from '../routes'

import { QUERY_CACHE_LAND_DESCRIPTION } from './queries/outworldCacheQueries'

export default class OutworldLandDescription extends Component {
  constructor() {
    super()

    this.state = {
      land: null,
      observer: null
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_LAND_DESCRIPTION
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            let land = data.current_land.location_details
            await this.setState({
              land: { ...land, sub_text: `You are currently in ${land.x}, ${land.y} ${land.name}` }
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  render() {
    return (
      <div>
        {
          this.state.land && (
            <div>
              <div className="outworld-land-description-title">{this.state.land.title}</div>
              <div className="outworld-land-description-description">{this.state.land.description}</div>
              <div className="outworld-land-description-sub_text">{this.state.land.sub_text}</div>
            </div>
          )
        }
      </div>
    )
  }
}
