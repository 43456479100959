import React, { Component } from 'react'

export default class Mail extends Component {
  render() {
    return (
      <div>
        Mail
      </div>
    )
  }
}
