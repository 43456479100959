import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Button } from 'react-bootstrap'
import gql from 'graphql-tag'

import Back from '../Common/Back'
import { MUTATION_RESTORE_MANA } from './queries/gameQueries'

export default class Fountain extends Component {
  constructor() {
    super()
    this.state = {
      mana_amount: 0
    }
  }

  handleManaAmountChange = (e) => {
    const mana_amount = (e.target.validity.valid) ? e.target.value : this.state.mana_amount;
    this.setState({
      ...this.state,
      mana_amount: mana_amount
    })
  }

  handleRestoreInputKeyPress = (e, restore_mana) => {
    if (e.key === 'Enter') {
      this.sendRestoreMana(restore_mana)
    }
  }

  sendRestoreMana = (restore_mana) => {
    const newMana = this.state.mana_amount
    restore_mana({
      variables: {
        mana: parseInt(newMana)
      }
    })

    this.setState({
      ...this.state,
      mana_amount: ''
    })
  }

  render() {
    return (
      <div>
        <h5>Fountain</h5>   
        <div className="fountain-wrapper">
          <div>
            Welcome to the fountain, step into the pools to replenish your mana
          </div>
          <Mutation mutation={gql`${MUTATION_RESTORE_MANA}`}>
            {(restore_mana, { data, loading, called, error }) => {
              let message = null
              if(error) {
                console.log("Error: FUIR1")
              } else {
                if(data && data.restore_mana) {
                  message = data.restore_mana.message
                  let status = data.restore_mana.status

                  switch (status) {
                    case 500:
                      message = (
                        <span className="status-message-fail">
                          {message}
                        </span>
                      )
                      break
                    case 200:
                      message = (
                        <span className="status-message-success">
                          {message}
                        </span>
                      )
                      break
                    default:
                      message = null
                  }
                }
              }

              return (
                <div className="parentContainer">
                  <div className="status-message-container">
                    {message}
                  </div>
                  <input pattern="[0-9]*" className="game-input" placeholder="Amount to restore" value={this.state.mana_amount} onChange={this.handleManaAmountChange} onKeyPress={e => {
                      this.handleRestoreInputKeyPress(e, restore_mana)
                    }}/>
                  <Button
                    id="restoreButton"
                    bsSize="small"
                    className="btn-margin game-button"
                    onClick={() => { this.sendRestoreMana(restore_mana) }}
                  >
                    Send
                  </Button>
                </div>
              )
            }}
          </Mutation>
        </div>
        <Back />
      </div>
    )
  }
}
