import gql from 'graphql-tag'

const MUTATION_RESET_ME = gql`
  mutation {
    reset_me {
      status
      message
    }
  }
`

const MUTATION_GENERATE_REFERRAL = gql`
  mutation {
    generate_referral {
      status
      message
    }
  }
`

const QUERY_ACCOUNT_REFERRALS = gql`
  query($id: Int!) {
    account(where: { id: { _eq: $id }}) {
      referralsByaccountId {
        id
        code
      }
      accountsByreferrer {
        id
        charactersByaccountId {
          id
          name
        }
      }
    }
  }
`

export {
  MUTATION_RESET_ME,
  MUTATION_GENERATE_REFERRAL,
  QUERY_ACCOUNT_REFERRALS
}