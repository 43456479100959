import jwt_decode from 'jwt-decode'

const fetchAndDecodeJWT = () => {
  const token = localStorage.getItem('auth:access_token');
  return token ? jwt_decode(token) : null
}

export const idFromJWT = () => {
  let decoded = fetchAndDecodeJWT()

  return decoded ? parseInt(decoded.user_id) : null
}

export const accountFromJWT = () => {
  let decoded = fetchAndDecodeJWT()

  return decoded ? parseInt(decoded.account_id) : null
}

export const isStaff = () => {
  let decoded = fetchAndDecodeJWT()

  return parseInt(decoded.auth_level)
}
