import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { Button } from 'react-bootstrap'
import gql from 'graphql-tag'

import { MUTATION_HEAL } from './queries/gameQueries'
import Back from '../Common/Back'

export default class Hospital extends Component {
  constructor() {
    super()
    this.state = {
      heal_amount: ''
    }
  }

  handleHealAmountChange = (e) => {
    const heal_amount = (e.target.validity.valid) ? e.target.value : this.state.heal_amount;
    this.setState({
      ...this.state,
      heal_amount: heal_amount
    })
  }

  handleHealInputKeyPress = (e, heal) => {
    if (e.key === 'Enter') {
      this.sendHeal(heal)
    }
  }

  sendHeal = (heal) => {
    const newHeal = this.state.heal_amount
    heal({
      variables: {
        heal: parseInt(newHeal)
      }
    })

    this.setState({
      ...this.state,
      heal_amount: 0
    })
  }

  render() {
    return (
      <div>
        <h5>Hospital</h5>   
        <div className="hospital-wrapper">
          <div>
            Welcome to the hospital. Step in to recover your health
          </div>
          <Mutation mutation={gql`${MUTATION_HEAL}`}>
            {(heal, { data, loading, called, error }) => {
              let message = null
              if(error) {
                console.log("Error: HUIR1")
              } else {
                if(data && data.heal) {
                  message = data.heal.message
                  let status = data.heal.status

                  switch (status) {
                    case 500:
                      message = (
                        <span className="status-message-fail">
                          {message}
                        </span>
                      )
                      break
                    case 200:
                      message = (
                        <span className="status-message-success">
                          {message}
                        </span>
                      )
                      break
                    default:
                      message = null
                  }
                }
              }

              return (
                <div className="parentContainer">
                  <div className="status-message-container">
                    {message}
                  </div>
                  <input pattern="[0-9]*" className="game-input" placeholder="Amount to heal" value={this.state.heal_amount} onChange={this.handleHealAmountChange} onKeyPress={e => {
                      this.handleHealInputKeyPress(e, heal)
                    }}/>
                  <Button
                    id="healButton"
                    bsSize="small"
                    className="btn-margin game-button"
                    onClick={() => { this.sendHeal(heal) }}
                  >
                    Send
                  </Button>
                </div>
              )
            }}
          </Mutation>
        </div>
        <Back />
      </div>
    )
  }
}
