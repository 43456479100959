import gql from 'graphql-tag'

const QUERY_MONSTER_LIST = gql`
  query {
    monster (order_by: { level: asc }) {
      id
      level
      name
    }
  }
`

const QUERY_ARMOUR_LIST = gql`
  query {
    armour(order_by: { min_defence: asc}) {
      id
      description
      cost
      name
      max_defence
      min_defence
      efficiency
    }
  }
`

const QUERY_WEAPON_LIST = gql`
  query {
    weapon(order_by: { min_damage: asc}) {
      id
      description
      cost
      name
      max_damage
      min_damage
      efficiency
    }
  }
`

const QUERY_WORLD_LIST = gql`
  query {
    world(order_by: { minlevel: asc}) {
      id
      name
      xsize
      ysize
      xportal
      yportal
      minlevel
      minmon
      maxmon
      alignmin
      alignmax
      alignchange
      special_portal
    }
  }
`

const MUTATION_UPDATE_MONSTER = gql`
  mutation($id: Int!, $set: monster_set_input!) {
    update_monster(
      where: { id: { _eq: $id}}
      _set: $set
    ) {
      returning {
        id
      }
    }
  }
`

const MUTATION_UPDATE_ARMOUR = gql`
  mutation($id: Int!, $set: armour_set_input!) {
    update_armour(
      where: { id: { _eq: $id}}
      _set: $set
    ) {
      returning {
        id
      }
    }
  }
`

const MUTATION_UPDATE_WEAPON = gql`
  mutation($id: Int!, $set: weapon_set_input!) {
    update_weapon(
      where: { id: { _eq: $id}}
      _set: $set
    ) {
      returning {
        id
      }
    }
  }
`

const MUTATION_UPDATE_WORLD = gql`
  mutation($id: Int!, $set: world_set_input!) {
    update_world(
      where: { id: { _eq: $id}}
      _set: $set
    ) {
      returning {
        id
      }
    }
  }
`

const MUTATION_INSERT_MONSTER = gql`
  mutation ($obj: [monster_insert_input!]!) {
    insert_monster(objects: $obj) {
      returning {
        id
      }
    }
  }
`

const MUTATION_INSERT_ARMOUR = gql`
  mutation ($obj: [armour_insert_input!]!) {
    insert_armour(objects: $obj) {
      returning {
        id
      }
    }
  }
`

const MUTATION_INSERT_WEAPON = gql`
  mutation ($obj: [weapon_insert_input!]!) {
    insert_weapon(objects: $obj) {
      returning {
        id
      }
    }
  }
`

const MUTATION_INSERT_WORLD = gql`
  mutation ($obj: [world_insert_input!]!) {
    insert_world(objects: $obj) {
      returning {
        id
      }
    }
  }
`

const MUTATION_INSERT_NEWS = gql`
  mutation ($obj: [news_updates_insert_input!]!) {
    insert_news_updates(objects: $obj) {
      returning {
        id
      }
    }
  }
`

const MUTATION_GENERATE_PORTALS = gql`
  mutation {
    generate_portals {
      status
      message
      payload
    }
  }
`

const MUTATION_CLEAR_MOBS = gql`
  mutation($world: Int!) {
    delete_spawn(
      where:{ landBylocationId: { world_id: { _eq: $world }}}
    ) {
      affected_rows
    }
  }
`

export {
  QUERY_ARMOUR_LIST,
  QUERY_MONSTER_LIST,
  QUERY_WEAPON_LIST,
  QUERY_WORLD_LIST,
  MUTATION_INSERT_ARMOUR,
  MUTATION_INSERT_MONSTER,
  MUTATION_INSERT_WEAPON,
  MUTATION_INSERT_WORLD,
  MUTATION_UPDATE_ARMOUR,
  MUTATION_UPDATE_MONSTER,
  MUTATION_UPDATE_WEAPON,
  MUTATION_UPDATE_WORLD,
  MUTATION_INSERT_NEWS,
  MUTATION_GENERATE_PORTALS,
  MUTATION_CLEAR_MOBS
}