import React, { Component } from 'react'

import gql from 'graphql-tag'

import { client } from '../routes'
import { QUERY_CACHE_ITEMS } from './queries/outworldCacheQueries'
import { format_item_state_name } from '../Utilities';

export default class OutworldItemList extends Component {
  constructor() {
    super()

    this.state = {
      items: [],
      observer: null
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_ITEMS
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              items: data.current_land.items
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  eat(id, state) {
    client.mutate({
      mutation: gql`
        mutation {
          eat(item: ${id}, state: ${state}) {
            status
            message
            payload
          }
        }
      `
    }).then((response) => {
      let payload = JSON.parse(response.data.eat.payload) || {}
      let message = (
        <div className={`eat-message ${!payload.good ? 'bad-action' : ''}`}>
          {response.data.eat.message}
        </div>
      )
      this.props.display_message(message)
    }).catch((e) => {
      console.log(e)
    })
  }

  render() {
    return (
      <div className="outworld-item-list">
        {
          this.state.items.map((item, i) => {
            return (
              <div key={i}>
                {item.count} x {format_item_state_name(item)}
                &nbsp;[<button 
                  className="link-button outworld-button" 
                  onClick={() => this.eat(item.item_id, item.state)}>
                  Eat
                </button>]
              </div>
            )
          })
        }
      </div>
    )
  }
}
