import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { Button } from 'react-bootstrap'
import './LoginModal.css'

import Login from '../Login/Login'
import Register from '../Login/Register'

export default class LoginModal extends Component {
  constructor () {
    super()
    this.state = {
      showModal: false,
      register: false
    }
    
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    ReactModal.setAppElement(document.getElementById('root'));
  }
  
  handleOpenModal () {
    this.setState({ showModal: true })
  }
  
  handleCloseModal () {
    this.setState({ showModal: false })
  }
  
  render () {
    return (
      <div className="login-wrapper">
        <Button
          id="qsLoginBtn"
          bsStyle="primary"
          className="btn-margin"
          onClick={this.handleOpenModal.bind(this)}
        >
          Log In
        </Button>
        <ReactModal 
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          className="login-modal"
          overlayClassName="login-overlay"
        >
          <div className="login-modal-tab-wrapper">
            <span 
              className={`login-modal-tab login-modal-tab-left ${this.state.register ? '' : 'login-modal-tab-clicked'}`}
              onClick={() => { this.setState({ register: false})}}
            >
              LOGIN
            </span>
            <span 
              className={`login-modal-tab login-modal-tab-right ${this.state.register ? 'login-modal-tab-clicked' : ''}`}
              onClick={() => { this.setState({ register: true})}}
            >
              REGISTER
            </span>
          </div>
          {
            !this.state.register && (
              <Login cancel={this.handleCloseModal} />
            )
          }
          {
            this.state.register && (
              <Register cancel={this.handleCloseModal} />
            )
          }
        </ReactModal>
      </div>
    )
  }
}
