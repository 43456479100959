import React, { Component } from 'react'

import { client } from '../routes'
import { QUERY_CACHE_LAND_BARRELS } from './queries/outworldCacheQueries'

export default class OutworldBarrels extends Component {
  constructor() {
    super()

    this.state = {
      barrels: []
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_LAND_BARRELS
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              barrels: data.current_land.barrels
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  render() {
    return (
      <div>
        {
          this.state.barrels.map((barrel) => {
            return (
              <div key={barrel.id}>
                <button className="no-select outworld-barrel link-button" onClick={() => { this.props.open_barrel(barrel.id)}}>
                  Barrel ({barrel.cost} keys)
                </button>
              </div>
            )
          })
        }
        {
          this.state.barrels.length === 0 && (
            <div className="outworld-no-barrels"></div>
          )
        }
      </div>
    )
  }
}
