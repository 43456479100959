import React, { Component } from 'react'
import UserInfo from './UserInfo'
import UserClock from './UserClock'

import { NavLink } from "react-router-dom"

import { client } from '../routes'

import { isStaff } from '../Utilities'

import './Navigation.css'
import { SUBSCRIPTION_PLAYERS_ONLINE } from './queries/navigationQueries'

export default class Navigation extends Component {
  constructor() {
    super()

    this.state = {
      players_online_observer: null,
      players_online: 0
    }

    this.init_subscription = this.init_subscription.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
  }

  componentWillUnmount() {
    if(this.state.players_online_observer) {
      this.state.players_online_observer.unsubscribe()
    }
  }

  init_subscription() {
    try {
      let observer = client.subscribe({
        query: SUBSCRIPTION_PLAYERS_ONLINE,
        fetchPolicy: 'network-only'
      }).subscribe({
        next: (new_data) => {
            if(new_data.data.players_online && new_data.data.players_online.length === 1) {
              let count = new_data.data.players_online[0].count
              this.setState({ players_online: count })
            }
        },
        error(err) { console.log(err) }
      })

      this.setState({ players_online_observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    this.init_subscription()
  }

  render() {
    //TODO: Fix layout; remove <br /> and replace with flex layout
    return (
      <div className="links-wrapper">
        <UserInfo />
        <UserClock />
        <div className="navigation-links">
          <NavLink activeClassName='link-is-active' exact={true} to="/game">Profile</NavLink> <br />
          <NavLink activeClassName='link-is-active' to="/game/outworld" className="bolded">Outworld</NavLink><br />
          <br />
          <NavLink activeClassName='link-is-active' to="/game/inventory">Inventory</NavLink><br />
          <NavLink activeClassName='link-is-active' to="/game/workout">Workout Center</NavLink><br />
          <NavLink activeClassName='link-is-active' to="/game/bank">Bank</NavLink><br />
          <br />
          <NavLink activeClassName='link-is-active' to="/game/village">Grimm Village</NavLink><br />
          <NavLink activeClassName='link-is-active' to="/game/heal">Hospital</NavLink><br />
          Market<br />
          {/* <NavLink activeClassName='link-is-active' to="/game/fountain">Fountain</NavLink><br /> */}
          <br />
          House <br />
          Clan <br />
          <br />
          <NavLink activeClassName='link-is-active' to="/game/members">Rankings</NavLink><br />
          <NavLink activeClassName='link-is-active' to="/game/mail">Post Office (0)</NavLink><br />
          Support Tickets (0)<br />
          <NavLink activeClassName='link-is-active' to="/game/online">Players Online <span className="bolded">({this.state.players_online})</span></NavLink><br />
          <br />
          <NavLink activeClassName='link-is-active' to="/game/news">News &amp; Updates (0)</NavLink><br />
          {
            isStaff() > 0 && (
              <div>
                <NavLink activeClassName='link-is-active' to="/game/admin">Administration</NavLink>
              </div>
            )
          }
          <NavLink activeClassName='link-is-active' to="/logout">Logout</NavLink>
        </div>

      </div>
    )
  }
}
