import gql from 'graphql-tag'

const SUBSCRIPTION_OUTWORLD_MAP = gql`
  subscription($id: Int) {
    character(where: {id: { _eq: $id}}) {
      id
      character_details {
        location
      }
      world_map {
        id
        lid
        x
        y
        pk
        portal
      }
    }
  }
`

const SUBSCRIPTION_OUTWORLD_LAND_OPTIONS = gql`
  subscription($loc: Int!) {
    land(where: { id: { _eq: $loc}}) {
      id
      owner_id
    }
  }
`

const SUBSCRIPTION_OUTWORLD_LAND_INFORMATION = gql`
  subscription($loc: Int!) {
    land(where: { id: { _eq: $loc}}) {
      id
      tax
      tax_collected
      owner_id
      land_extra_details {
        gold_to_collect
      }
      characterByownerId {
        name
      }
    }
  }
`

const SUBSCRIPTION_OUTWORLD_LAND_MONSTERS = gql`
  subscription($loc: Int!) {
    land(where: { id: { _eq: $loc}}) {
      id
      mobs_here {
        mobs_here
      }
      spawnsBylocationId(limit: 24, order_by: { id: asc }) {
        id
        monsterBymonsterId {
          name
          level
        }
      }
    }
  }
`

const SUBSCRIPTION_OUTWORLD_LAND_BARRELS = gql`
  subscription($loc: Int!) {
    land(where: { id: { _eq: $loc}}) {
      id
      barrelsBylocationId(limit: 5) {
        cost
        id
      }
    }
  }
`

const SUBSCRIPTION_OUTWORLD_LAND_DESCRIPTION = gql`
  subscription($loc: Int!) {
    land(where: { id: { _eq: $loc}}) {
      id
      title
      description
      x
      y
      worldByworldId {
        name
      }
    }
  }
`

const MUTATION_TAKEOVER_CLAIM = gql`
  mutation($loc: Int!) {
    takeover(location: $loc) {
      status
      message
      payload
    }
  }
`

const MUTATION_OPEN_BARREL = gql`
  mutation($id: Int!) {
    open_barrel(id: $id) {
      status
      message
      payload
    }
  }
`

const MUTATION_SET_LAND_OPTIONS = gql`
  mutation($loc: Int!, $opt: Int!, $val: String!) {
    landoption(location: $loc, option: $opt, value: $val) {
      status
      message
      payload
    }
  }
`

const MUTATION_MOVE = gql`
  mutation($direction: Int!) {
    move(direction: $direction) {
      status
      message
      payload
    }
  }
`

const QUERY_LAND_DESCRIPTION = gql`
  query($id: Int!) {
    character(where: { id: { _eq: $id} }) {
      id
    }
  }
`

const QUERY_OUTWORLD_STATE = gql`
  query ($id: Int!) {
    character (where: { id: { _eq: $id}}) {
      auth_level
      location_details {
        x
        y
        description
        tax
        tax_collected
        pk
        portal
        title
        name
        id
        loc
        owner
      }
    }
  }
`

const SUBSCRIPTION_OUTWORLD_STATE = gql`
  subscription ($id: Int!, $min: Int!, $max: Int!) {
    character(where: {id: {_eq: $id}}) {
      auth_level
      character_world_portals {
        id
        name
        minlevel
        lid
      }
      landsByownerId {
        id
        title
      }
      landBylocation {
        id
        owner_id
        charactersBylocation(order_by: { level: desc }) {
          id
          name
          level
        }
        mobs_here {
          mobs_here
        }
        spawnsBylocationId(
          limit: 24, 
          order_by: { id: asc },
          where: { 
            monsterBymonsterId: {
              _and: [
                {
                level: {
                  _gte: $min
                }
                }
                {
                  level: {
                    _lte: $max
                  }
                }
              ]
            }
          }
        ) {
          id
          monsterBymonsterId {
            name
            level
          }
        }
        land_extra_details {
          gold_to_collect
          wood
          stone
          clay
        }
        barrelsBylocationId(limit: 5) {
          id
          cost
        }
      }
      character_item_inventory {
        name
        count
        state
        type
        item_id
      }
      location_details {
        x
        y
        description
        tax
        resource_bonus
        tax_collected
        pk
        portal
        title
        name
        id
        loc
        owner
      }
      world_map {
        id
        lid
        x
        y
        pk
        portal
      }
    }
  }
`

const SUBSCRIPTION_OUTWORLD_PLAYERS = gql`
  subscription($loc: Int!) {
    land(where: { id: { _eq: $loc}}) {
      charactersBylocation{
        id
        name
        level
      }
    }
  }
`

const SUBSCRIPTION_OUTWORLD_LAND_PORTALS = gql`
  subscription($id: Int!) {
    character(where: { id: { _eq: $id}}) {
      id
      landsByownerId {
        id
        title
      }
    }
  }
`

const QUERY_OUTWORLD_WORLD_PORTALS = gql`
  query($id: Int!) {
    world (
      where: { 
        special_portal: { _eq: false}
      },
      order_by: { minlevel: asc }
    ) {
      id
      name
      minlevel
      landsByworldId(where: { portal: { _eq: true }}) {
        portal
        id
      }
    }
    character(where: { id: { _eq: $id}}) {
      level
    }
  }
`

export {
  MUTATION_MOVE,
  MUTATION_TAKEOVER_CLAIM,
  MUTATION_SET_LAND_OPTIONS,
  MUTATION_OPEN_BARREL,
  SUBSCRIPTION_OUTWORLD_MAP,
  SUBSCRIPTION_OUTWORLD_PLAYERS,
  SUBSCRIPTION_OUTWORLD_LAND_PORTALS,
  SUBSCRIPTION_OUTWORLD_LAND_OPTIONS,
  SUBSCRIPTION_OUTWORLD_LAND_INFORMATION,
  SUBSCRIPTION_OUTWORLD_LAND_DESCRIPTION,
  SUBSCRIPTION_OUTWORLD_LAND_MONSTERS,
  SUBSCRIPTION_OUTWORLD_LAND_BARRELS,
  SUBSCRIPTION_OUTWORLD_STATE,
  QUERY_OUTWORLD_WORLD_PORTALS,
  QUERY_LAND_DESCRIPTION,
  QUERY_OUTWORLD_STATE
}