import React, { Component } from 'react'

export default class OutworldFightResult extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hide_log: props.hide_log
    }
  }
  render() {
    let align_class = this.props.log.loot && this.props.log.loot.align < 0 ? 'negative' : 'positive'
    let stats = this.props.log.stats

    return (
      <div className="outworld-fight-result-wrapper">
        {
          this.props.log.fight_log && (
            <div className="outworld-fight-result-data">
              {
                !this.state.hide_log && (
                <div className="outworld-fight-log-wrapper" onClick={() => this.setState({ hide_log: !this.hide_log })}>
                  {
                    this.props.log.fight_log.map((entry) => {
                      return (
                        <div key={entry.round} className="outworld-fight-log-row">
                          <div className="outworld-fight-log-player-attack">{entry.player}</div>
                          <div className="outworld-fight-log-monster-attack">{entry.monster}</div>
                        </div>
                      )
                    })
                  }
                </div>
                )
              }
              <div className="outworld-fight-loot-wrapper">
                <div className="outworld-fight-outcome" onClick={() => this.setState({ hide_log: !this.state.hide_log })}>
                  {this.props.log.outcome}
                </div>
                <div className="outworld-battle-stats">
                  <div className="outworld-battle-character-stats">
                    You hit the {this.props.log.mob_name} {stats.character.hit} times, crit {stats.character.crit}, dodged {stats.character.dodge}, and blocked {stats.character.block} times
                  </div>
                  <div className="outworld-battle-mob-stats">
                    The {this.props.log.mob_name} hit you {stats.mob.hit} times, crit {stats.mob.crit}, dodged {stats.mob.dodge}, and blocked {stats.mob.block} times
                  </div>
                </div>
                {
                  this.props.log.loot && (
                    <div className="outworld-fight-loot">
                      {
                        this.props.log.loot.align && (
                          <div className="outworld-fight-loot-align">
                            Your alignment changed by <span className={`align-${align_class}`}>{this.props.log.loot.align }</span>
                          </div>
                        )
                      }
                      <div className="outworld-fight-loot-gold-exp">
                        You gained <span className="outworld-fight-result-gold">{this.props.log.loot.gold}</span> gold 
                        and <span className="outworld-fight-result-experience">{this.props.log.loot.experience}</span> experience
                      </div>
                      {
                        this.props.log.bonuses && (
                          <div className="outworld-fight-bonuses">
                            {
                              this.props.log.bonuses.map((bonus, i) => <div key={i}>{bonus}</div>)
                            }
                          </div>
                        )
                      }
                      {
                        this.props.log.leveled && (
                          <div className="outworld-fight-leveled">
                            You gained a level!
                          </div>
                        )
                      }
                      {
                        this.props.log.loot.item_drop && (
                          <div className="outworld-fight-item-drop">
                            You  found {this.props.log.loot.item_drop.name} ({this.props.log.loot.item_drop.minvalue} - {this.props.log.loot.item_drop.maxvalue}) on the corpse
                          </div>
                        )
                      }
                      {
                        this.props.log.loot.drop && this.props.log.loot.drop.length > 0 && (
                          <div className="outworld-loot-drop-wrapper">
                            {
                              this.props.log.loot.drop.map((loot) => {
                                return (
                                  <div key={loot.key} className="outworld-fight-loot-drop">
                                    You found <span className={`outworld-loot-drop outworld-fight-drop-${loot.type} `}> 
                                      {loot.amount} {loot.type}
                                      {loot.type !== 'topaz' && loot.amount > 1 ? 's' : ''}
                                    </span> on the corpse
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      }
                      {
                        this.props.log.loot.other_drop && this.props.log.loot.other_drop.length > 0 && (
                          <div className="outworld-loot-other-drop-wrapper">
                            {
                              this.props.log.loot.other_drop.map((loot, i) => {
                                return (
                                  <div key={i}>
                                    {
                                      loot.type === "barrel" && (
                                        <div className="outworld-loot-barrel-drop">
                                          You found a barrel ({loot.cost} keys) on the monster's corpse 
                                        </div>
                                      )
                                    }
                                    {
                                      loot.type === "item" && (
                                        <div className="outworld-item-drop">
                                          You found <span className="outworld-item-drop-name">{loot.name}</span> on the monster's corpse
                                        </div>
                                      )
                                    }
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
