import React, { Component } from 'react'

import { client } from '../routes'
import { QUERY_CACHE_WORLD_MAP } from './queries/outworldCacheQueries'

export default class OutworldMap extends Component {
  constructor(props) {
    super(props)

    this.state = {
      world_map: [],
      observer: null
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_WORLD_MAP
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              world_map: data.current_land.world_map
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  render() {
    if(this.state.world_map.length === 0) {
      return 'Loading...'
    }

    let map_data = this.state.world_map
    let loc = map_data.find((t) => t.lid === this.props.loc)

    if(!loc) {
      return null
    }

    let width = Math.floor((7 * 16) + 6 / 2) - 1
    let height = Math.floor((7 * 20) + 6 / 2) - 1

    let xmax = map_data[map_data.length - 1].x
    let ymax = map_data[0].y

    let yoffset = loc.y - 3
    let xoffset = loc.x - 3

    let ylimit = loc.y + 3
    let xlimit = loc.x + 3

    if(ylimit > ymax) {
      yoffset -= ylimit - ymax
      ylimit = ymax
    }

    if(xlimit > xmax) {
      xoffset -= xlimit - xmax
      xlimit = xmax
    }

    if(yoffset < 0) {
      ylimit += (yoffset * -1)
    }

    if(xoffset < 0) {
      xlimit += (xoffset * -1)
    }

    return (
      <div>
        <div className="outworld-map" style={{ height: height, width: width}}>
          {
            map_data.map((land) => {
              const pk = land.pk ? "pk-tile" : ""
              const portal = land.portal ? "portal-tile" : ""
              const yedge = land.y === ymax ? "top-edge" : land.y === 0 ? "bottom-edge" : ""
              const xedge = land.x === xmax ? "right-edge" : land.x === 0 ? "left-edge" : ""

              return land.x >= xoffset && land.y >= yoffset && land.x <= xlimit && land.y <= ylimit  ? (
                <div key={land.lid} className={`outworld-map-tile ${pk} ${portal} ${yedge} ${xedge}`}>
                {
                  land.lid === this.props.loc ? <div className="outworld-little-guy"></div> : null
                }
                </div>
              ) : null
            })
          }
        </div>
      </div>
    )
  }
}
