import React from 'react'

export const formatName = (name, auth_level) => {
  let name_class = auth_level > 0 ? `staff-${auth_level}` : 'user-name'
  return (
    <span className={`staff ${name_class}`}>
      {name}
    </span>
  )
}
