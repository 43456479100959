import React, { Component } from 'react'

import { client } from '../routes'
import { MUTATION_INSERT_NEWS } from './queries/adminQueries.js'
import { idFromJWT, formatName } from '../Utilities';
import { QUERY_NEWS_LIST } from '../Game/queries/gameQueries';

export default class News extends Component {
  constructor() {
    super()

    this.state = {
      news: [],
      add_news: { text: '', type: 'update', character_id: idFromJWT() },
      message: '',
      message_state: 'success'
    }

    this.insert_news = this.insert_news.bind(this)
    this.display_message = this.display_message.bind(this)
    this.scratch_news = this.scratch_news.bind(this)
    this.query_news = this.query_news.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  display_message(message, message_state) {
    this.setState({
      message: message,
      message_state: message_state === "update" ? 'success' : 'error'
    })
  }

  scratch_news(item) {
    console.log("TODO: Implement scratch news")
  }

  async query_news() {
    client.query({
      query: QUERY_NEWS_LIST,
      fetchPolicy: 'network-only'
    }).then((response) => {
      let news = response.data.news_updates
      if(news) {
        this.setState({
          news: news
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  async insert_news(e) {
    e.preventDefault()
    if(!this.state.add_news) {
      this.setState({ message: "Nothing to post"})
      return
    }

    client.mutate({
      mutation: MUTATION_INSERT_NEWS,
      variables: { obj: [this.state.add_news] }
    }).then((response) => {
      console.log(response)
      this.query_news()
      console.log("TODO: Display message")
    }).catch((e) => {
      console.log("TODO: Display message")
      console.log(e)
    })
  }

  componentDidMount() {
    this.query_news()
  }

  render() {
    return (
      <div>
        <div className={`admin-display-${this.state.message_state}-message`}>{this.state.message}</div>
        <div className="admin-add-news">
          <div className="admin-add-news-button-wrapper">
            {
              this.state.news && (
                <form className="add-news-form" onSubmit={this.insert_news}>
                  <table className="admin-add-news-table">
                    <thead>
                      <tr>
                        <th>Character</th>
                        <th>Text</th>
                        <th className="admin-news-type-header">Type</th>
                        <th className="admin-news-hide-header">Header</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="4">{this.state.add_news.text}</td>
                      </tr>
                      <tr>
                          <td>
                            {this.state.add_news.id || ' '}
                          </td>
                          <td>
                            <input autoFocus className="input-news-text-input" type="text" value={this.state.add_news.text} placeholder="Text" name="an_text" onChange={(e) => this.setState({ add_news: { ...this.state.add_news, text: e.target.value }})} required />
                          </td>
                          <td className="admin-news-type">
                            {
                              this.state.add_news.type === "update" && (
                                <div onClick={(e) => this.setState({ add_news: { ...this.state.add_news, type: "news" }})} className="input-is-type">UPDATE</div>
                              )
                            }
                            {
                              this.state.add_news.type === "news" && (
                                <div onClick={(e) => this.setState({ add_news: { ...this.state.add_news, type: "update" }})} className="input-is-type">NEWS</div>
                              )
                            }
                          </td>
                          <td className="admin-news-hide">
                            <button className="admin-add-news-submit game-button" type="submit">Save</button>
                          </td>
                      </tr>
                      {
                        this.state.news.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td className="admin-news-character">
                                {
                                  formatName(item.characterBycharacterId.name, item.characterBycharacterId.auth_level)
                                }
                              </td>
                              <td>{item.text}</td>
                              <td className="admin-news-type">
                                {
                                  item.type === "update" && (
                                    <div className="input-is-type">UPDATE</div>
                                  )
                                }
                                {
                                  item.type === "news" && (
                                    <div className="input-is-type">NEWS</div>
                                  )
                                }
                              </td>
                              <td className="admin-news-hide">
                                <button className="game-button" type="button" onClick={() => this.scratch_news(item)}>Hide</button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </form>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
