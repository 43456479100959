import React, { Component } from 'react'

import gql from 'graphql-tag'
import { Subscription } from 'react-apollo'
import { SUBSCRIPTION_YOUR_INFO } from './queries/navigationQueries'

import { formatName, formatNumber, idFromJWT, formatLife, formatExperience, formatAlign, formatLevel } from '../Utilities'

import './UserInfo.css'

export default class UserInfo extends Component {
  render() {
    return (
      <div className="userinfo-wrapper">
        <Subscription subscription={gql`${SUBSCRIPTION_YOUR_INFO}`} variables={{character_id: idFromJWT()}}>
          {({ loading, error, data }) => {
            if (loading) return <p>Loading...</p>;
            if (error) {
              return <p>Error: Couldn't fetch stats</p>;
            }
            let character = data.character[0]
            let id = character.id
            character = character.character_details
            return (
              <div>
                <div className="info-header"><span className="bolded underlined">Your Info:</span></div>
                <div><span className="bolded">My ID:</span> {formatNumber(id)}</div>
                <div><span className="bolded">Name:</span> {formatName(character.name, character.auth_level)}</div>
                <div><span className="bolded">Level:</span> {formatLevel(character.level)}</div>
                <div><span className="bolded">Life:</span> {formatLife(character.health, character.max_health)}</div>
                <div><span className="bolded">Exp:</span> {formatExperience(character.experience, character.experience_needed)}</div>
                <div><span className="bolded">Mana:</span> {formatNumber(character.mana)}/{formatNumber(character.max_mana)}</div>
                <div><span className="bolded">Align:</span> {formatAlign(character.alignment)}</div>
                <div><span className="bolded">Hand:</span> {formatNumber(character.gold)}</div>
                <div><span className="bolded">Bank:</span> {formatNumber(character.bank)}</div>
                <div><span className="bolded">Fights:</span> {formatNumber(character.fights)}/{formatNumber(character.allowed_fights)}</div>
              </div>
            )
          }}
        </Subscription>
      </div>
    )
  }
}
