let enhancement_types = [
  { text: 'gold', type: 1 },
  { text: 'experience', type: 2 },
  { text: 'gem', type: 3 },
  { text: 'heal', type: 4 },
  { text: 'mana', type: 5 },
  { text: 'efficiency', type: 6 },
  { text: 'attract', type: 7 },
  { text: 'item', type: 8 }
]

export const enhance_type = (type) => {  
  return enhancement_types.find((t) => t.type === type).text
}

export const enhance_types = () => {
  return enhancement_types
}