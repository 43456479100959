export const format_item_state_name = (item) => {
  let states = {
    types: {
      1: {
        1: "Fresh",
        2: "Drying",
        3: "Dried"
      },
      2: {
        1: "Fresh",
        2: "Rotting",
        3: "Rotten",
        4: "Pickled"
      },
      3: {
        1: "Fresh",
        2: "Rotting",
        3: "Rotten",
        4: "Pickled"
      },
      4: {
        1: "Fresh",
        2: "Aged"
      }
    }
  }

  return  `${states.types[item.type][item.state]} ${item.name}`
}