import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Subscription } from 'react-apollo'

import MessageBox from './MessageBox'

import { SUBSCRIPTION_CHAT } from './queries/chatQueries'

import { formatName } from '../Utilities'

import './Chat.css'

export default class Chat extends Component {
  render() {
    return (
      <div className="chat-wrapper">
        <div className="chat-links"></div>
        <div className="chat-messages">
          <Subscription subscription={gql`${SUBSCRIPTION_CHAT}`}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) {
                return <p>Error: Your shit broke</p>;
              }
              return (
                <div>
                  <div>
                    {
                      data && data.chat.length > 0 && data.chat.map((message) => {
                        let character = message.characterBycharacterId
                        return (
                          <div key={message.id}>
                            {formatName(character.name, character.auth_level)}
                            <span className="chat-says"> says: </span>
                            <span>{message.message}</span>
                          </div>
                        )
                      })
                    }
                    {
                      data.chat.length === 0 && 
                      <div>No Chat</div>
                    }
                  </div>
                </div>
              )
            }}
          </Subscription>
        </div>
        <div className="chat-messagebox">
            <MessageBox />
        </div>
      </div>
    )
  }
}
