import React, { Component } from 'react'

import { client } from '../routes'
import { QUERY_MONSTER_LIST, MUTATION_UPDATE_MONSTER, MUTATION_INSERT_MONSTER } from './queries/adminQueries.js'

export default class AdminMonsters extends Component {
  constructor() {
    super()

    this.state = {
      monsters: [],
      edit_monster: { name: '', level: ''},
      default_edit_monster: { name: '', level: ''},
      add_monster: {},
      message: '',
      message_state: 'success'
    }

    this.query_monsters = this.query_monsters.bind(this)
    this.insert_monster = this.insert_monster.bind(this)
    this.save_monster = this.save_monster.bind(this)
    this.saveMonsterClick = this.saveMonsterClick.bind(this)
    this.display_message = this.display_message.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  display_message(message, message_state) {
    this.setState({
      message: message,
      message_state: message_state === 1 ? 'success' : 'error'
    })
  }

  saveMonsterClick(e) {
    e.preventDefault()
    if(this.state.edit_monster.id) {
      this.save_monster()
    } else {
      this.insert_monster()
    }
  }

  async query_monsters() {
    client.query({
      query: QUERY_MONSTER_LIST,
      fetchPolicy: 'network-only'
    }).then((response) => {
      let monsters = response.data.monster
      if(monsters) {
        this.setState({
          monsters: monsters
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  async insert_monster() {
    if(!this.state.add_monster) {
      this.setState({ message: "No monster selected to edit"})
      return
    }

    client.mutate({
      mutation: MUTATION_INSERT_MONSTER,
      variables: { obj: [this.state.edit_monster] }
    }).then(() => {
      this.query_monsters()
    }).catch((e) => {
      console.log(e)
    })
  }

  async save_monster() {
    if(!this.state.edit_monster.id) {
      this.setState({ message: "No monster selected to edit"})
      return
    }
    let { id, ...set } = this.state.edit_monster

    client.mutate({
      mutation: MUTATION_UPDATE_MONSTER,
      variables: { id: id, set: set }
    }).then(() => {
      this.query_monsters()
    }).catch((e) => {
      console.log(e)
    })
  }

  componentDidMount() {
    this.query_monsters()
  }

  render() {
    return (
      <div>
        <div className={`admin-display-${this.state.message_state}-message`}>{this.state.message}</div>
        <div className="admin-edit-monster">
          <div className="admin-edit-monster-button-wrapper">
            {
              this.state.monsters && this.state.monsters.length > 0 && (
                <form className="edit-monster-form" onSubmit={this.saveMonsterClick}>
                  <table className="admin-edit-monster-table">
                    <thead>
                      <tr>
                        <th onClick={() => this.setState({ edit_monster: this.state.default_edit_monster})}>ID</th>
                        <th>Level</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>
                            {this.state.edit_monster.id}
                          </td>
                          <td>
                            <input autoFocus ref={(input) => { this.level_input = input}} pattern="[0-9]*" className="number-input" value={this.state.edit_monster.level} placeholder="Level" name="am_level" onChange={(e) => this.setState({ edit_monster: { ...this.state.edit_monster, level: e.target.value }})} required />
                          </td>
                          <td>
                            <input className="input-monster-edit-name" type="text" value={this.state.edit_monster.name} placeholder="Name" name="am_name" onChange={(e) => this.setState({ edit_monster: { ...this.state.edit_monster, name: e.target.value }})} required />
                          </td>
                          <td>
                            <button className="admin-edit-monster-submit game-button" type="submit">Save</button>
                          </td>
                      </tr>
                      {
                        this.state.monsters.map((monster) => {
                          return (
                            <tr key={monster.id}>
                              <td>{monster.id}</td>
                              <td>{monster.level}</td>
                              <td>{monster.name}</td>
                              <td>
                                <button className="game-button" type="button" onClick={() => { this.setState({ edit_monster: monster }); this.level_input.focus()}}>Edit</button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </form>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
