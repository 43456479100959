import React, { Component } from 'react'

import { client } from '../routes'

import { idFromJWT } from '../Utilities'
import { QUERY_CACHE_LAND_OWNER } from './queries/outworldCacheQueries'

export default class OutworldLandOptions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      location: props.location,
      input_val: '',
      owner: null,
      observer: null
    }

    this.takeover_claim_land = this.takeover_claim_land.bind(this)
    this.set_land_option = this.set_land_option.bind(this)
    this.reset_inputs = this.reset_inputs.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this)
    this.collect_tax = this.collect_tax.bind(this)
    this.collect_resources = this.collect_resources.bind(this)

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_LAND_OWNER
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              owner: data.current_land.owner_id
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  takeover_claim_land() {
    this.props.takeover_claim({
      loc: this.props.location
    })
  }

  set_land_option(opt, val) {
    let vars = {
      loc: this.props.location,
      opt: opt,
      val: val ? val : this.state.input_val.toString()
    }

    this.props.set_option(vars)
    this.reset_inputs()
  }

  reset_inputs() {
    this.setState({ 
      setting_tax: false, 
      setting_owner: false, 
      setting_title: false, 
      setting_description: false, 
      setting_input: false,
      input_val: '',
      setting_pk: false,
      setting_resource_bonus: false
    })
  }

  async collect_tax() {
    this.set_land_option(6)
  }

  async collect_resources() {
    this.set_land_option(8)
  }

  handleInputChange(e) {
    this.setState({ 
      ...this.state,
      input_val: e.target.value
    })
  }

  handleInputKeyPress(e, opt) {
    if (e.key === 'Enter') {
      this.set_land_option(opt)
    }
  }

  render() {
    return (
      <div className="outworld-land-options-wrapper">
        {
          this.state.owner !== idFromJWT() && (
            <div className="outworld-land-claim-takeover">
              <div className="outworld-claim-takeover-links">
                <button className="outworld-button link-button" onClick={() => this.takeover_claim_land()}>
                {
                  !this.state.owner && (
                    `Claim (1 key)`
                  )
                }
                {
                  this.state.owner && this.state.owner !== idFromJWT() && (
                    `Takeover (2 key)`
                  )
                }
                </button>
              </div>
            </div>
          )
        }
        <div className="outworld-land-owner-options-wrapper">
          <div className="outworld-land-owner-options">
            {
              this.state.owner === idFromJWT() && !this.state.setting_input && (
                <div className="outworld-land-options-flex-container">
                  <div className="outworld-land-options-flex-row">
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={() => this.setState({ setting_tax: true, setting_input: true })}>
                        Set Tax
                      </button>
                    </div>
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={() => this.setState({ setting_resource_bonus: true, setting_input: true })}>
                        Resource Bonus
                      </button>
                    </div>
                  </div>
                  <div className="outworld-land-options-flex-row">
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={() => this.setState({ setting_owner: true, setting_input: true })}>
                        Give Away
                      </button>
                    </div>
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={this.collect_resources}>
                        Get Resources
                      </button>
                    </div>
                  </div>
                  <div className="outworld-land-options-flex-row">
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={() => this.setState({ setting_title: true, setting_input: true })}>
                        Title
                      </button>
                    </div>
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={this.collect_tax}>
                        Collect Tax
                      </button>
                    </div>
                  </div>
                  <div className="outworld-land-options-flex-row">
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={() => this.setState({ setting_description: true, setting_input: true })}>
                        Description
                      </button>
                    </div>
                  </div>
                  <div className="outworld-land-option-flex-row">
                    <div className="outworld-land-options-flex-column">
                      <button className="outworld-button link-button" onClick={() => this.setState({ setting_pk: true, setting_input: true })}>
                        PK Status
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
            <div className="outworld-land-input-wrapper">
              {
                this.state.setting_tax && (
                  <div>
                    <input className="outworld-land-option-input" placeholder="New Tax"
                      onChange={this.handleInputChange} onKeyPress={e => this.handleInputKeyPress(e, 1)} />
                    <span className="outworld-land-option-confirm" onClick={() => this.set_land_option(1)}>[&#x2714;]</span>
                    <span className="outworld-land-option-cancel" onClick={() => this.reset_inputs()}>[x]</span>
                  </div>
                )
              }
              {
                this.state.setting_owner && (
                  <div>
                    <input className="outworld-land-option-input" placeholder="Owner ID"
                      onChange={this.handleInputChange} onKeyPress={e => this.handleInputKeyPress(e, 2)} />
                    <span className="outworld-land-option-confirm" onClick={() => this.set_land_option(2)}>[&#x2714;]</span>
                    <span className="outworld-land-option-cancel" onClick={() => this.reset_inputs()}>[x]</span>
                  </div>
                )
              }
              {
                this.state.setting_title && (
                  <div>
                    <input className="outworld-land-option-input" placeholder="New Title"
                      onChange={this.handleInputChange} onKeyPress={e => this.handleInputKeyPress(e, 3)} />
                    <span className="outworld-land-option-confirm" onClick={() => this.set_land_option(3)}>[&#x2714;]</span>
                    <span className="outworld-land-option-cancel" onClick={() => this.reset_inputs()}>[x]</span>
                  </div>
                )
              }
              {
                this.state.setting_description && (
                  <div>
                    <input className="outworld-land-option-input" placeholder="New Description"
                      onChange={this.handleInputChange} onKeyPress={e => this.handleInputKeyPress(e, 4)} />
                    <span className="outworld-land-option-confirm" onClick={() => this.set_land_option(4)}>[&#x2714;]</span>
                    <span className="outworld-land-option-cancel" onClick={() => this.reset_inputs()}>[x]</span>
                  </div>
                )
              }
              {
                this.state.setting_pk && (
                  <div className="outworld-land-option-pk-wrapper">
                    <span className="outworld-land-option-nonpk outworld-map-tile pk-tile" onClick={() => this.set_land_option(5, '1')}></span>
                    <span className="outworld-land-option-pk outworld-map-tile" onClick={() => this.set_land_option(5, '0')}></span>
                    <span className="outworld-land-option-cancel" onClick={() => this.reset_inputs()}>[x]</span>
                  </div>
                )
              }
              {
                this.state.setting_resource_bonus && (
                  <div>
                    <input className="outworld-land-option-input" placeholder="New Bonus"
                      onChange={this.handleInputChange} onKeyPress={e => this.handleInputKeyPress(e, 7)} />
                    <span className="outworld-land-option-confirm" onClick={() => this.set_land_option(7)}>[&#x2714;]</span>
                    <span className="outworld-land-option-cancel" onClick={() => this.reset_inputs()}>[x]</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
