import React, { Component } from 'react'
import { QUERY_NEWS_ITEM } from './queries/gameQueries';
import { Query } from 'react-apollo';
import { formatDate, formatName } from '../Utilities';

export default class LatestNewsItem extends Component {
  render() {
    return (
      <div>
        <Query query={QUERY_NEWS_ITEM} fetchPolicy='network-only'>
        {({loading, error, data}) => {
            if(error) {
              console.log(error)
            }

            if(data && data.news_updates) {
              let news = data.news_updates
              return (
                <div>
                  {
                    news.map((item) => {
                      return (
                        <div key={item.id} className="news-item">
                          [{formatDate(new Date(item.created_at))}] {formatName(item.characterBycharacterId.name, item.characterBycharacterId.auth_level)} - <span className="news-item-type">{item.type === "update" ? "Update: " : "News: "}</span>{item.text}
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
            
            return null
          }}
        </Query>
      </div>
    )
  }
}
