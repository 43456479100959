import React, { Component } from 'react'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import { formatName } from '../Utilities'
import { AUTH_SERVICE_URL } from '../constants';

let QUERY_USERS_ONLINE = gql`
  query($last: timestamptz){
    character(
      where: { character_details: { last_action: { _gte: $last } } }
    ) {
      id
      name
      level
      auth_level
      character_details {
        last_action
      }
    }
  }
`

let date = new Date(new Date() - 20*60000)

export default class LandingPage extends Component {
  constructor() {
    super()

    this.state = {
      players: [],
      loading: true
    }

    this.fetch_players_online = this.fetch_players_online.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  async fetch_players_online() {
    try {
      fetch(`${AUTH_SERVICE_URL}/online`, {
        method: "GET",
        mode: "cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      })
      .then((response) => {
        if (response.status >= 400) {
          response.json().then((res) => {
            this.displayMessages(res.message)  
          })

          return  
        }

        return response.json()
      })
      .then((returnedData) => {
        if(returnedData) {
          let players = returnedData.character && returnedData.character.length > 0 ? returnedData.character : []
          this.setState({ players: players, loading: false })
        }
      })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    this.fetch_players_online()
  }

  render() {
    return (
      <div>
        Welcome to the landing page. Here be dragons <br /><br />

        <div>
          Players Online:
        </div>
        <div>
          {
            this.state.players && this.state.players.map((character) => {
              return (
                <div key={character.id} className="online-character-row">
                  {formatName(character.name, character.auth_level)} ({character.level})
                </div>
              )
            })
          }
          {
            this.state.loading && (
              <div>Loading...</div>
            )
          }
          {
            !this.state.loading && this.state.players.length === 0 && (
              <div>
                None. :(
              </div>
            )
          }
        </div>
        {/* <Query query={QUERY_USERS_ONLINE} variables={{ last: date }}>
          {({loading, error, data}) => {
            if(error) {
              console.log(error)
            }

            if(data) {
              let players = data.character
              return (
                <div>
                  {
                    players && players.map((character) => {
                      return (
                        <div key={character.id} className="online-character-row">
                          {formatName(character.name, character.auth_level)} ({character.level})
                        </div>
                      )
                    })
                  }
                </div>
              )
            } else {
              return 'Loading...'
            }
          }}
        </Query> */}
        {/* <Query query={QUERY_STATUS}>
        {({loading, error, data}) => {
            if(error) {
              console.log(error)
            }

            if(data && data.proxy_status) {
              let status = data.proxy_status
              return (
                <div>
                  {
                    status.status === 200 && (
                      <div>
                        {status.message} - {status.status}
                      </div>
                    )
                  }
                </div>
              )
            }
            
            return null
          }}
        </Query> */}
      </div>
    )
  }
}
