import React, { Component } from 'react'

import { client } from '../routes'
import { QUERY_CACHE_CHARACTERS_HERE } from './queries/outworldCacheQueries'

export default class OutworldPlayers extends Component {
  constructor() {
    super()

    this.state = {
      show_players: true,
      players: []
    }

    this.show_hide_players = this.show_hide_players.bind(this)

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_CHARACTERS_HERE
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              players: data.current_land.players
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  show_hide_players() {
    this.setState({ show_players: !this.state.show_players })
  }

  render() {
    return (
      <div>
        <div className="outworld-players-here-header" onClick={this.show_hide_players}>Players Here</div>
        <div>
          {
            this.state.show_players && this.state.players.map((character) => {
              return (
                <div key={character.id} className="outworld-players-here-player">
                    {character.name} <span className="outworld-players-here-level">({character.level})</span>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
