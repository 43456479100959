export const castFormatDate = (str) => {
  return formatDate(new Date(str))
}

export const castDaysSince = (str) => {
  return daysSince(new Date(str))
}

export const daysSince = (date) => {
  let day = 24*60*60*1000 // hours*minutes*seconds*milliseconds
  let current = new Date()

  return  Math.round(Math.abs((date.getTime() - current.getTime())/(day)))
}

export const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-us', {
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
  }).format(date)
}

export const currentDate = () => {
  return formatDate(Date.now())
}
