import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import Armour from './Armour'
import GemShop from './GemShop'
import Market from './Market'
import Jail from './Jail'
import Rankings from './Rankings'
import Staff from './Staff'
import VillageNavigation from './VillageNavigation'
import Weapon from './Weapon'

import './Village.css'

export default class Village extends Component {
  render() {
    return (
      <div className="village-wrapper">
        <Route exact path={this.props.match.path} component={VillageNavigation} />
        <Route path={`${this.props.match.url}/armour`} component={Armour} />
        <Route path={`${this.props.match.url}/gemshop`} component={GemShop} />
        <Route path={`${this.props.match.url}/jail`} component={Jail} />
        <Route path={`${this.props.match.url}/market`} component={Market} />
        <Route path={`${this.props.match.url}/rankings`} component={Rankings} />
        <Route path={`${this.props.match.url}/staff`} component={Staff} />
        <Route path={`${this.props.match.url}/weapon`} component={Weapon} />
      </div>
    )
  }
}
