import React, { Component } from 'react'
import { Mutation } from "react-apollo"

import { Button } from 'react-bootstrap'

import { MUTATION_CHAT_ADD } from './queries/chatQueries'

import { idFromJWT } from '../Utilities/sessionVariables'

export default class MessageBox extends Component {
  constructor() {
    super()
    this.state = {
      messageBoxValue: ''
    }
  }

  handleMessageBoxValueChange = (e) => {
    this.setState({
      ...this.state,
      messageBoxValue: e.target.value
    })
  }

  handleMessageBoxKeyPress = (e, addMessage) => {
    if (e.key === 'Enter') {
      this.sendMessage(addMessage)
    }
  }

  sendMessage = (addMessage) => {
    const newMessage = this.state.messageBoxValue
      const userId = idFromJWT()
      addMessage({
        variables: {
          objects: [{
            message: newMessage,
            character_id: userId
          }]
        }
      })

      this.setState({
        ...this.state,
        messageBoxValue: ''
      })
  }

  render() {
    return (
      <div className="chat-messagebox-wrapper">
        <Mutation mutation={MUTATION_CHAT_ADD}>
          {(addMessage, { data, loading, called, error }) => {
            if(error) {
              // console.log(error)
            }
            return (
              <div className="parentContainer">
                <input className="chat-input" placeholder="Add a message" value={this.state.messageBoxValue} onChange={this.handleMessageBoxValueChange} onKeyPress={e => {
                    this.handleMessageBoxKeyPress(e, addMessage)
                  }}/>
                <Button
                  id="sendButton"
                  bsSize="small"
                  className="btn-margin game-button"
                  onClick={() => { this.sendMessage(addMessage) }}
                >
                  Send
                </Button>
              </div>
            )
          }}
        </Mutation>
        
      </div>
    )
  }
}
