import React, { Component } from 'react'

import { Subscription } from 'react-apollo'
import { SUBSCRIPTION_ONLINE } from './queries/gameQueries';

import { formatName } from '../Utilities'

export default class Online extends Component {
  render() {
    let date = new Date(new Date() - 20*60000) // 20 minutes ago
    return (
      <div>
        <Subscription subscription={SUBSCRIPTION_ONLINE} variables={{last: date }}>
          {({loading, error, data}) => {
            if(error) {
              console.log(error)
            }

            if(data) {
              let players = data.character
              return (
                <div>
                  {
                    players && players.map((character) => {
                      return (
                        <div key={character.id} className="online-character-row">
                          {formatName(character.name, character.auth_level)} ({character.level})
                        </div>
                      )
                    })
                  }
                </div>
              )
            } else {
              return 'Loading...'
            }
          }}
        </Subscription>
      </div>
    )
  }
}
