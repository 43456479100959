import React, { Component } from 'react'
import { Mutation, Subscription } from 'react-apollo'
import { Button } from 'react-bootstrap'
import gql from 'graphql-tag'

import { MUTATION_DEPOSIT_WITHDRAW, SUBSCRIPTION_BANK } from './queries/gameQueries'
import Back from '../Common/Back'
import { idFromJWT } from '../Utilities/sessionVariables'
import { formatNumber } from '../Utilities/numberFormatter'

export default class Bank extends Component {
  constructor() {
    super()
    this.state = {
      gold_amount: 0
    }
  }

  handleGoldAmountChange = (e) => {
    const gold_amount = (e.target.validity.valid) ? e.target.value : this.state.gold_amount;
    this.setState({
      ...this.state,
      gold_amount: gold_amount
    })
  }

  handleGoldInputKeyPress = (e, deposit) => {
    if (e.key === 'Enter') {
      this.sendDepositWithdraw(deposit)
    }
  }

  sendDepositWithdraw = (deposit_withdraw, isWithdrawl) => {
    const deposit_amount = isWithdrawl ? this.state.gold_amount * -1: this.state.gold_amount
    deposit_withdraw({
      variables: {
        amount: parseInt(deposit_amount)
      }
    })
    this.setState({
      ...this.state,
      gold_amount: ''
    })
  }

  render() {
    return (
      <div>
        <h5>Bank</h5>   
        <div className="bank-wrapper">
          {/* TODO: Change to query and use return data to update? */}
          <Subscription subscription={SUBSCRIPTION_BANK} variables={{id: idFromJWT()}}>
            {({loading, error, data}) => {
              let bank = 0
              if(data && data.character) {
                bank = data.character[0].character_details.bank
              }
              return (
                <div>
                  Welcome to the bank. Your account holds {formatNumber(bank)} gold
                </div>
              )
            }}
          </Subscription>
          <Mutation mutation={gql`${MUTATION_DEPOSIT_WITHDRAW}`}>
            {(deposit_withdraw, { data, loading, called, error }) => {
              let message = null
              if(error) {                
                console.log("Error: BUID1")
              } else {
                if(data && data.deposit_withdraw) {
                  message = data.deposit_withdraw.message
                  let status = data.deposit_withdraw.status

                  switch (status) {
                    case 500:
                      message = (
                        <span className="status-message-fail">
                          {message}
                        </span>
                      )
                      break
                    case 200:
                      message = (
                        <span className="status-message-success">
                          {message}
                        </span>
                      )
                      break
                    default:
                      message = null
                  }
                }
              }

              return (
                <div className="parentContainer">
                  <div className="status-message-container">
                    {message}
                  </div>
                  <input pattern="[0-9]*" className="game-input" placeholder="Amount to deposit/withdraw" value={this.state.gold_amount} onChange={this.handleGoldAmountChange} onKeyPress={e => {
                      this.handleGoldInputKeyPress(e, deposit_withdraw)
                    }}/>
                  <Button
                    id="depositButton"
                    bsSize="small"
                    className="btn-margin game-button"
                    onClick={() => { this.sendDepositWithdraw(deposit_withdraw) }}
                  >
                    Deposit
                  </Button>
                  <Button
                    id="withdrawButton"
                    bsSize="small"
                    className="btn-margin game-button"
                    onClick={() => { this.sendDepositWithdraw(deposit_withdraw, true) }}
                  >
                    Withdraw
                  </Button>
                </div>
              )
            }}
          </Mutation>
        </div>
        <Back />
      </div>
    )
  }
}
