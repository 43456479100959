import gql from 'graphql-tag'

const SUBSCRIPTION_CHAT = gql`
  subscription subscribe_chat {
    chat(order_by: { id: desc }, limit: 100) {
      id
      message
      characterBycharacterId {
        name
        id
        auth_level
      }
    }
  }
`

const MUTATION_CHAT_ADD = gql`
  mutation insert_chat($objects: [chat_insert_input!]!){
    insert_chat(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export {
  SUBSCRIPTION_CHAT,
  MUTATION_CHAT_ADD
}