import React, { Component } from 'react'
import gql from 'graphql-tag'

import OutworldMap from './OutworldMap'
import OutworldCompass from './OutworldCompass'
import OutworldFightResult from './OutworldFightResult'
import OutworldLandDescription from './OutworldLandDescription'
import OutworldLandInformation from './OutworldLandInformation'
import { idFromJWT } from '../Utilities'

import { client } from '../routes'

import './Outworld.css'
import { QUERY_OUTWORLD_STATE, MUTATION_SET_LAND_OPTIONS, MUTATION_TAKEOVER_CLAIM, MUTATION_OPEN_BARREL, SUBSCRIPTION_OUTWORLD_STATE } from './queries/outworldQueries'
import { QUERY_CACHE_LAND_STATE, QUERY_CACHE_LAND_BARRELS } from './queries/outworldCacheQueries'

import OutworldMonsters from './OutworldMonsters'
import OutworldPlayers from './OutworldPlayers'
import OutworldLandPortals from './OutworldLandPortals'
import OutworldWorldPortals from './OutworldWorldPortals'
import OutworldLandOptions from './OutworldLandOptions'
import OutworldBarrels from './OutworldBarrels'
import OutworldItemList from './OutworldItemList';
import Battle from '../Battle/Battle';

export default class Outworld extends Component {
  constructor() {
    super()

    this.state = {
      compass_message: '',
      location_id: 0,
      released: true,
      hide_log: true,
      keys_bound: false,
      outworld_state_observer: null,
      min_mob: 0,
      max_mob: 100
    }

    this.refresh_state = this.refresh_state.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.move_action = this.move_action.bind(this)
    this.fight_monster = this.fight_monster.bind(this)
    this.spawn_mobs = this.spawn_mobs.bind(this)
    this.onKeyPressed = this.onKeyPressed.bind(this)
    this.onKeyReleased = this.onKeyReleased.bind(this)
    this.display_message = this.display_message.bind(this)
    this.teleport = this.teleport.bind(this)
    this.set_option = this.set_option.bind(this)
    this.takeover_claim = this.takeover_claim.bind(this)
    this.open_barrel = this.open_barrel.bind(this)
    this.init_subscription = this.init_subscription.bind(this)
    this.set_mob_filter = this.set_mob_filter.bind(this)
    this.refresh_subscription = this.refresh_subscription.bind(this)
  }

  async set_mob_filter(min, max) {
    await this.setState({ min_mob: min, max_mob: max})
    this.refresh_subscription()
  }

  init_subscription() {
    try {
      client.writeQuery({
        query: QUERY_CACHE_LAND_STATE,
        data: {
          current_land: {
            id: 0, mobs_here: 0, owner_id: null, monsters: [], barrels: [], landsByownerId: [], players: [], lands: [], worlds: [], world_map: [], items: [],
            land_extra_details: { gold_to_collect: 0 },
            location_details: { x: null, y: null, description: null, tax: null, resource_bonus: null, tax_collected: null, pk: false, portal: false, title: null, name: null, id: null, loc: null, owner: null }
          } //Default values so query doesn't crash on first read
        }
      })

      let observer = client.subscribe({
        query: SUBSCRIPTION_OUTWORLD_STATE,
        variables: { id: idFromJWT(), min: this.state.min_mob, max: this.state.max_mob },
        fetchPolicy: 'network-only'
      }).subscribe({
        next(new_data) {
            if(new_data.data.character && new_data.data.character.length > 0) {
              let character = new_data.data.character[0]
              
              let data = {
                current_land: {
                  id: character.location_details.id,
                  owner_id: character.landBylocation.owner_id,
                  mobs_here: character.landBylocation.mobs_here.length > 0 ? character.landBylocation.mobs_here[0].mobs_here : 0,
                  monsters: character.landBylocation.spawnsBylocationId,
                  land_extra_details: character.landBylocation.land_extra_details,
                  location_details: character.location_details,
                  barrels: character.landBylocation.barrelsBylocationId,
                  lands: character.landsByownerId,
                  players: character.landBylocation.charactersBylocation,
                  worlds: character.character_world_portals,
                  world_map: character.world_map,
                  items: character.character_item_inventory
                }
              }

              client.writeQuery({
                query: QUERY_CACHE_LAND_STATE,
                data: data
              })
            }
        },
        error(err) { console.log(err) }
      })

      this.setState({ outworld_state_observer: observer })
    } catch(e) {
      console.log(e)
    }

    //TODO: Why is subscription firing twice
  }

  async componentWillUnmount() {
    if(this.state.outworld_state_observer) {
      await this.state.outworld_state_observer.unsubscribe()
    }
  }

  open_barrel(id) {
    client.mutate({
      mutation: MUTATION_OPEN_BARREL,
      variables: { id: id }
    }).then((response) => {
      if(response.data.open_barrel.status === 200) {
        let payload = JSON.parse(response.data.open_barrel.payload)
        let message_style = !payload.success ? 'fail' : 'success'
        this.setState({
          status_message: (<div className={`status-message-${message_style}`}>{response.data.open_barrel.message}</div>)
        })

        let data = client.readQuery({
          query: QUERY_CACHE_LAND_BARRELS
        })

        data.current_land.barrels = data.current_land.barrels.filter((b) => b.id !== id)

        client.writeQuery({
          query: QUERY_CACHE_LAND_BARRELS,
          data: data
        })
      } else {
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.open_barrel.message}</div>)
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  takeover_claim(vars) {
    client.mutate({
      mutation: MUTATION_TAKEOVER_CLAIM,
      variables: vars
    }).then((response) => {
      if(response.data.takeover.status === 200) {
        this.setState({
          status_message: (<div className="status-message-success">{response.data.takeover.message}</div>)
        })     
      } else {
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.takeover.message}</div>)
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  set_option(vars) {
    client.mutate({
      mutation: MUTATION_SET_LAND_OPTIONS,
      variables: vars
    }).then((response) => {
      if(response.data.landoption.status === 200) {
        this.setState({
          compass_message: '',
          status_message: (<div className="status-message-success">{response.data.landoption.message}</div>)
        })     
      } else {
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.landoption.message}</div>),
          compass_message: ''
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  spawn_mobs() {
    client.mutate({
      mutation: gql`
        mutation {
          spawn_mobs {
            status
            message
            payload
          }
        }
      `
    }).then((response) => {
      if(response.data.spawn_mobs.status === 200) {
        this.setState({
          status_message: (<div className="status-message-success">{response.data.spawn_mobs.message}</div>)
        })     
      } else {
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.spawn_mobs.message}</div>)
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  onKeyReleased() {
    this.setState({
      released: true
    })
  }

  onKeyPressed(e) {    
    if(this.state.keys_bound) {
      if(!this.state.released) {
        return
      }
      
      let direction, action
      switch(e.keyCode) {
        case 37: // up
          direction = 2
          break
        case 38: // left
          direction = 1
          break
        case 39: // right
          direction = 3
          break
        case 40: // down
          direction = 4
          break
        case 65: // a
          action = 1
          break;
        default:
          return
      }

    if(direction) {
      e.preventDefault()
      this.move_action(direction)
    } else if (action) {
      e.preventDefault()
      switch(action) {
          case 1:
            let mob = this.monsters.get_mob()
            if(mob) {
              this.fight_monster(mob)
            }
            break
          default:
            return
        }
      }

      this.setState({
        released: false
      })
    }
  }

  async refresh_state() {
    client.query({
      query: QUERY_OUTWORLD_STATE,
      variables: { id: idFromJWT()},
      fetchPolicy: 'network-only'
    }).then((response) => {
      let character = response.data.character[0].location_details
      if(character) {
        this.setState({
          location_id: character.loc
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  move_action(direction) {
    this.setState({ display_message: null })
    client.mutate({
      mutation: gql`
        mutation {
          move(direction: ${direction}) {
            status
            message
            payload
          }
        }
      `
    }).then((response) => {
      console.log('TODO: Return location data from mutation')
      if(response.data.move.status === 200) {
        let payload = JSON.parse(response.data.move.payload).update_character.returning[0]
        this.setState({
          compass_message: '',
          status_message: (<div className="status-message-success">{response.data.move.message}</div>),
          location_id: response.data.move.status === 200 ? payload.location : this.state.location_id
        })     
      } else {
        let payload = JSON.parse(response.data.move.payload)
        let location = payload && payload.location ? payload.location : this.state.location_id
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.move.message}</div>),
          compass_message: '',
          location_id: location
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  fight_monster(monster_id) {
    client.mutate({
      mutation: gql`
        mutation {
          fight(monster: ${monster_id}, location: ${this.state.location_id}) {
            status
            message
            payload
          }
        }
      `
    }).then((response) => {
      if(response.data.fight.status === 200) {
        try {
          let payload = JSON.parse(response.data.fight.payload)
          let update = async (newLocation) => {
            await this.setState({
              compass_message: '',
              status_message: (<OutworldFightResult hide_log={this.state.hide_log} log={payload.fight_result} />),
              location_id: 0
            })
            await this.setState({
              location_id: newLocation
            })
          }

          if(payload.fight_result.player_won) {
            let data = client.readQuery({
              query: QUERY_CACHE_LAND_STATE
            })
  
            data.current_land.monsters = data.current_land.monsters.filter((m) => m.id !== monster_id)
            data.current_land.mobs_here = data.current_land.mobs_here - 1
            client.writeQuery({
              query: QUERY_CACHE_LAND_STATE,
              data: data
            })
          }

          update(payload.location)
        } catch (e) {
          console.log(e)
        }     
      } else {
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.fight.message}</div>),
          compass_message: ''
        })
      }
    })
  }

  display_message(message) {
    if(message) {
      setTimeout(() => {
        this.setState({ ...this.state, status_message: message})
      }, 100)
      //TODO: Dirty hack to deal with hasura 1s subscriptions
    }
  }

  teleport(to_loc) {
    client.mutate({
      mutation: gql`
        mutation {
          teleport(location: ${to_loc}) {
            status
            message
            payload
          }
        }
      `
    }).then((response) => {
      console.log('TODO: Return location data from mutation')
      if(response.data.teleport.status === 200) {
        let payload = JSON.parse(response.data.teleport.payload)
        this.setState({
          compass_message: '',
          status_message: (<div className="status-message-success">{response.data.teleport.message}</div>),
          location_id: response.data.teleport.status === 200 ? payload.location : this.state.location_id
        })     
      } else {
        this.setState({
          compass_message: '',
          status_message: (<div className="status-message-fail">{response.data.teleport.message}</div>)
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  render() {
    return (
      <div className={`outworld-event-wrapper ${this.state.keys_bound ? 'keys-bound' : ''}`} tabIndex="0" onKeyDown={this.onKeyPressed} onKeyUp={this.onKeyReleased}>
        <div className="outworld-status-message">
          {this.state.status_message}
        </div>
        <div className="outworld-wrapper">
          <div className="outworld-column">
            <div className="outworld-tile outworld-description-actions-wrapper">
              <div className="outworld-description-actions-content">
                {
                  this.state.action_panel && (
                    <div className="outworld-action-panel">{this.state.action_panel}</div>
                  )
                }
                {
                  !this.state.action_panel &&
                  <div className="outworld-land-description">
                    <OutworldLandDescription location={this.state.location_id} />
                  </div>
                }
              </div>
              <div className="outworld-actions-wrapper">
                {
                  this.state.action_panel && 
                    <div className="outworld-actions-close">
                      <button className="link-button outworld-button" onClick={() => this.setState({ action_panel: null })}>Close</button>
                    </div>
                }
                {
                  !this.state.action_panel && (
                    <div className="outworld-action-list">
                      <div className="outworld-action-item">
                        <button className="link-button outworld-button" onClick={() => this.setState({ 
                          action_panel: (
                            <OutworldItemList display_message={this.display_message} />
                          )
                        })}>Items</button>
                      </div>
                      <div className="outworld-action-item">
                        {/* <button className="link-button outworld-button" onClick={() => this.setState({ action_panel: (<div>Potion List Goes Here</div>)})}>Potions</button> */}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="outworld-tile outworld-monsters-barrels">
              <div className="outworld-monster-section">
                <Battle />
                {/* <OutworldMonsters ref={instance => { this.monsters = instance }} location={this.state.location_id} fight={this.fight_monster} set_filter={this.set_mob_filter} min_mob={this.state.min_mob} max_mob={this.state.max_mob} /> */}
              </div>
              <div className="outworld-barrel-section">
                <OutworldBarrels location={this.state.location_id} open_barrel={this.open_barrel} />
              </div>
            </div>
            <div className="outworld-tile outworld-land-information">
              <OutworldLandInformation location={this.state.location_id} />
            </div>
          </div>
          <div className="outworld-column outworld-right-column">
            <div className="outworld-compass-map-wrapper">
              <div className="outworld-tile outworld-compass">
                <OutworldCompass move={this.move_action} message={this.state.compass_message} />
              </div>
              {/* <div className="outworld-tile outworld-map-wrapper">
                <OutworldMap loc={this.state.location_id} map_data={this.state.map_data} />
              </div> */}
            </div>
            <div className="outworld-tile outworld-portals-players">
              <OutworldPlayers location={this.state.location_id} />
              <OutworldWorldPortals location={this.state.location_id} teleport={this.teleport} />
              <OutworldLandPortals teleport={this.teleport} />
            </div> 
            <div className="outworld-tile outworld-takeover-settings">
              <OutworldLandOptions set_option={this.set_option} takeover_claim={this.takeover_claim} location={this.state.location_id} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  async refresh_subscription() {
    await this.state.outworld_state_observer.unsubscribe()
    await this.setState({ outworld_state_observer: null })
    setTimeout(() => {
    this.init_subscription()
    }, 0)
  }

  componentDidMount() {
    setTimeout(() => {
      this.init_subscription()
      this.refresh_state()
    }, 0)
  }
}
