import React, { Component } from 'react'
import Monsters from './AdminMonsters'
import { Button } from 'react-bootstrap'

import './Admin.css'
import Armour from './AdminArmour'
import Weapon from './AdminWeapons'
import World from './AdminWorlds'
import Back from '../Common/Back'
import News from './AdminNews'

export default class Admin extends Component {
  constructor() {
    super()

    this.state = {
      show_monsters: false,
      show_armour: false,
      show_weapons: false,
      show_worlds: false,
      show_news: false
    }
  }
  render() {
    return (
      <div className="admin-container">
        <div className="admin-header">
          Admin Panel
        </div>
        <div className="admin-monster-section">
          <Button
            id="admin_monsters_section"
            bsSize="small"
            className="btn-margin game-button"
            onClick={() => { this.setState({ show_monsters: !this.state.show_monsters }) }}
          >
            {
              !this.state.show_monsters ? 'Monsters' : 'Hide Monsters'
            }
          </Button>
          {
            this.state.show_monsters && <Monsters />
          }
        </div>
        <div className="admin-armour-section">
          <Button
            id="admin_armour_section"
            bsSize="small"
            className="btn-margin game-button"
            onClick={() => { this.setState({ show_armour: !this.state.show_armour }) }}
          >
            {
              !this.state.show_armour ? 'Armour' : 'Hide Armour'
            }
          </Button>
          {
            this.state.show_armour && <Armour />
          }
        </div>
        <div className="admin-weapons-section">
          <Button
            id="admin_weapons_section"
            bsSize="small"
            className="btn-margin game-button"
            onClick={() => { this.setState({ show_weapons: !this.state.show_weapons }) }}
          >
            {
              !this.state.show_weapons ? 'Weapon' : 'Hide Weapon'
            }
          </Button>
          {
            this.state.show_weapons && <Weapon />
          }
        </div>
        <div className="admin-worlds-section">
          <Button
            id="admin_worlds_section"
            bsSize="small"
            className="btn-margin game-button"
            onClick={() => { this.setState({ show_worlds: !this.state.show_worlds }) }}
          >
            {
              !this.state.show_worlds ? 'World' : 'Hide World'
            }
          </Button>
          {
            this.state.show_worlds && <World />
          }
        </div>
        <div className="admin-news-section">
          <Button
            id="admin_news_section"
            bsSize="small"
            className="btn-margin game-button"
            onClick={() => { this.setState({ show_news: !this.state.show_news }) }}
          >
            {
              !this.state.show_news ? 'News' : 'Hide News'
            }
          </Button>
          {
            this.state.show_news && <News />
          }
        </div>
        <br />
        <Back />
      </div>
    )
  }
}
