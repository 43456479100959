import React from 'react'
import { Route, Router } from 'react-router-dom'
import App from './App'
import Game from './Game/Game'
import Auth from './Auth/Auth'
import history from './history'
import ApolloClient from 'apollo-client'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { GRAPHQL_URL, GRAPHQL_WSS_URL } from './constants'

const connectionParams = () => {
  const token = localStorage.getItem('auth:access_token');
  return token ? { headers: { 'Authorization': 'Bearer ' + token }} : {};
};

const refreshSubscription = () => {
  wsLink.subscriptionClient.close(false, false);
}

const wsLink = new WebSocketLink({
  uri: GRAPHQL_WSS_URL,
  options: {
    connectionParams: connectionParams,
    onError: refreshSubscription,
    reconnect: true,
  }
})

window.addEventListener("beforeunload", (e) => {
  //Attempt to close the subscription client before page reloads in order to avoid a FF issue where socket won't properly reconnect
  wsLink.subscriptionClient.close()  
});

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('auth:access_token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);


export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
})

const provideClient = (component) => {
  return (
    <ApolloProvider client={client}>
      {component}
    </ApolloProvider>
  )
}

const auth = new Auth()

export const makeMainRoutes = () => {
  return (
    <Router history={history}>
      <div className="container">
        <Route
          path="/"
          render={(props) => provideClient(<App auth={auth} {...props} />)}
        />
        <Route
          path="/game"
          render={(props) => provideClient(<Game auth={auth} {...props} />)}
        />
        <Route
          path="/logout"
          render={() => { 
            auth.logout()
            client.resetStore()

            //NOTE: Another dirty window.location hack. Such is life
            window.location = '/'

            return null
          }} />
      </div>
    </Router>
  )
}
