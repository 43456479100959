import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import { client } from '../routes'
import { QUERY_WORLD_LIST, MUTATION_UPDATE_WORLD, MUTATION_INSERT_WORLD, MUTATION_GENERATE_PORTALS, MUTATION_CLEAR_MOBS } from './queries/adminQueries.js'

export default class AdminWorlds extends Component {
  constructor() {
    super()

    this.state = {
      worlds: [],
      edit_world: { name: '', xsize: '', ysize: '', xportal: '', yportal: '', minlevel: '', minmon:'', maxmon: '', alignmin: '', alignmax: '', alignchange: 1, special_portal: false},
      default_edit_world: { name: '', xsize: '', ysize: '', xportal: '', yportal: '', minlevel: '', minmon:'', maxmon: '', alignmin: '', alignmax: '', alignchange: 1, special_portal: false},
      add_world: {},
      message: '',
      message_state: 'success'
    }

    this.clear_mobs = this.clear_mobs.bind(this)
    this.query_worlds = this.query_worlds.bind(this)
    this.insert_world = this.insert_world.bind(this)
    this.save_world = this.save_world.bind(this)
    this.save_world_click = this.save_world_click.bind(this)
    this.generate_portals = this.generate_portals.bind(this)
    this.display_message = this.display_message.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  clear_mobs(id) {
    client.mutate({
      mutation: MUTATION_CLEAR_MOBS,
      variables: { world: id }
    }).then((response) => {
      if(response.data.delete_spawn) {
        this.display_message(`Deleted ${response.data.delete_spawn.affected_rows} mobs`)
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  display_message(message, message_state) {
    this.setState({
      message: message,
      message_state: message_state === 1 ? 'success' : 'error'
    })
  }

  save_world_click(e) {
    e.preventDefault()
    if(this.state.edit_world.id) {
      this.save_world()
    } else {
      this.insert_world()
    }
  }

  async query_worlds() {
    client.query({
      query: QUERY_WORLD_LIST,
      fetchPolicy: 'network-only'
    }).then((response) => {
      let worlds = response.data.world
      if(worlds) {
        this.setState({
          worlds: worlds
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  async insert_world() {
    if(!this.state.add_world) {
      this.setState({ message: "No world selected to edit"})
      return
    }

    client.mutate({
      mutation: MUTATION_INSERT_WORLD,
      variables: { obj: [this.state.edit_world] }
    }).then(() => {
      this.query_worlds()
    }).catch((e) => {
      console.log(e)
    })
  }

  async save_world() {
    if(!this.state.edit_world.id) {
      this.setState({ message: "No world selected to edit"})
      return
    }
    let { id, ...set } = this.state.edit_world

    client.mutate({
      mutation: MUTATION_UPDATE_WORLD,
      variables: { id: id, set: set }
    }).then(() => {
      this.query_worlds()
    }).catch((e) => {
      console.log(e)
    })
  }

  async generate_portals() {
    client.mutate({
      mutation: MUTATION_GENERATE_PORTALS
    }).then((data) => {
      this.display_message(data.data.generate_portals.message, data.data.generate_portals.status === 200 ? 1 : 0)
    }).catch((e) => {
      console.log(e)
    })
  }

  componentDidMount() {
    this.query_worlds()
  }

  render() {
    return (
      <div>
        <div>
          <Button bsSize="small" className="btn-margin game-button" onClick={this.generate_portals}>Generate Portals</Button>
        </div>
        <div className={`admin-display-${this.state.message_state}-message`}>{this.state.message}</div>
        <div className="admin-edit-world">
          <div className="admin-edit-world-button-wrapper">
            {
              this.state.worlds && this.state.worlds.length > 0 && (
                <form className="edit-world-form" onSubmit={this.save_world_click}>
                  <table className="admin-edit-world-table">
                    <thead>
                      <tr>
                        <th onClick={() => this.setState({ edit_world: this.state.default_edit_world})}>ID</th>
                        <th>Name</th>
                        <th>xSize</th>
                        <th>ySize</th>
                        <th>xPortal</th>
                        <th>yPortal</th>
                        <th>Min Lvl</th>
                        <th>Min Mon</th>
                        <th>Max Mon</th>
                        <th>Align Min</th>
                        <th>Align Max</th>
                        <th>Align Change</th>
                        <th>Special Portal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>
                            {this.state.edit_world.id}
                          </td>
                          <td>
                            <input autoFocus className="input-world-text-input" type="text" value={this.state.edit_world.name} placeholder="Name" name="awo_name" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, name: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.xsize} placeholder="xSize" name="awo_xsize" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, xsize: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.ysize} placeholder="ySize" name="awo_ysize" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, ysize: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.xportal} placeholder="xPort" name="awo_xportal" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, xportal: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.yportal} placeholder="yPort" name="awo_yportal" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, yportal: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.minlevel} placeholder="mLvl" name="awo_minlevel" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, minlevel: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.minmon} placeholder="mMon" name="awo_minmon" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, minmon: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.maxmon} placeholder="MMon" name="awo_maxmon" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, maxmon: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.alignmin} placeholder="mAlign" name="awo_alignmin" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, alignmin: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="input-world-number-input" value={this.state.edit_world.alignmax} placeholder="MAlign" name="awo_alignmax" onChange={(e) => this.setState({ edit_world: { ...this.state.edit_world, alignmax: e.target.value }})} required />
                          </td>
                          <td>
                            {
                              this.state.edit_world.alignchange === 1 && (
                                <div onClick={(e) => this.setState({ edit_world: { ...this.state.edit_world, alignchange: -1 }})} className="input-align-change">+</div>
                              )
                            }
                            {
                              this.state.edit_world.alignchange === -1 && (
                                <div onClick={(e) => this.setState({ edit_world: { ...this.state.edit_world, alignchange: 1 }})} className="input-align-change">-</div>
                              )
                            }
                          </td>
                          <td>
                            {
                              this.state.edit_world.special_portal && (
                                <div onClick={(e) => this.setState({ edit_world: { ...this.state.edit_world, special_portal: false }})} className="input-is-special-portal">YES</div>
                              )
                            }
                            {
                              !this.state.edit_world.special_portal && (
                                <div onClick={(e) => this.setState({ edit_world: { ...this.state.edit_world, special_portal: true }})} className="input-is-special-portal">NO</div>
                              )
                            }
                          </td>
                          <td>
                            <button className="admin-edit-world-submit game-button" type="submit">Save</button>
                          </td>
                      </tr>
                      {
                        this.state.worlds.map((world) => {
                          return (
                            <tr key={world.id}>
                              <td>{world.id}</td>
                              <td>{world.name}</td>
                              <td>{world.xsize}</td>
                              <td>{world.ysize}</td>
                              <td>{world.xportal}</td>
                              <td>{world.yportal}</td>
                              <td>{world.minlevel}</td>
                              <td>{world.minmon}</td>
                              <td>{world.maxmon}</td>
                              <td>{world.alignmin}</td>
                              <td>{world.alignmax}</td>
                              <td>
                                {
                                  world.alignchange === 1 && (
                                    <div className="input-align-change">+</div>
                                  )
                                }
                                {
                                  world.alignchange === -1 && (
                                    <div className="input-align-change">-</div>
                                  )
                                }
                              </td>
                              <td>
                                {
                                  world.special_portal && (
                                    <div className="input-is-special-portal">YES</div>
                                  )
                                }
                                {
                                  !world.special_portal && (
                                    <div className="input-is-special-portal">NO</div>
                                  )
                                }
                              </td>
                              <td>
                                <button className="game-button" type="button" onClick={() => this.setState({ edit_world: world })}>Edit</button>
                                <button className="game-button" type="button" onClick={() => this.clear_mobs(world.id)}>Clear</button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </form>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
