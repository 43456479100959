  import React, { Component } from 'react'

  import gql from 'graphql-tag'
  import { Query } from 'react-apollo'

  import { QUERY_CRIMINALS } from './queries/villageQueries'

  export default class Jail extends Component {
    render() {
      return (
        <Query query={gql`${QUERY_CRIMINALS}`}>
          {({ loading, error, data }) => {
                if (loading) {
                  return <p>Loading...</p>;
                }

                if (error) {
                  console.log(error);
                  
                  return <p>Error: Your shit broke</p>;
                }

                return (
                  <div>
                    <div>
                      {
                        data.account.length > 0 && data.account.map((criminal) => {
                          return (
                            <div key={criminal.id}>
                              {criminal.username}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
            }}
        </Query>
      )
    }
  }
  