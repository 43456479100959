import React, { Component } from 'react'

import { formatNumber, idFromJWT } from '../Utilities'
import { QUERY_CACHE_LAND_INFORMATION } from './queries/outworldCacheQueries'

import { client } from '../routes'

export default class OutworldLandInformation extends Component {
  constructor() {
    super()

    this.state = {
      land: null,
      gold_to_collect: null,
      observer: null
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_LAND_INFORMATION
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              land: data.current_land.location_details,
              owner: data.current_land.owner_id,
              gold_to_collect: data.current_land.land_extra_details.gold_to_collect,
              res: data.current_land.land_extra_details.wood + data.current_land.land_extra_details.stone + data.current_land.land_extra_details.clay
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  render() {
    return (
      <div className="outworld-land-information-wrapper">
        {
          this.state.land && (
            <div>
              <div>
                {this.state.land.owner ? this.state.land.owner : 'noone'} owns this land
              </div>
              <div>
                The tax here is {this.state.land.tax}%
              </div>
              {
                this.state.land.resource_bonus > 0 && (
                  <div>
                    You gain {this.state.land.resource_bonus}% bonus resources here
                  </div>
                )
              }
              <div>
                Tax generated: {formatNumber(this.state.land.tax_collected)} gold
              </div>
              {
                this.state.owner === idFromJWT() && (
                  <div>
                    <span className="collect-span">Tax to collect: {this.state.gold_to_collect.toLocaleString()} gold{this.state.res ? `, ${this.state.res.toLocaleString()} resources` : null}</span>
                  </div>
                )
              }
            </div>
          )
        }
        {
          !this.state.land && (
            <div>Loading...</div>
          )
        }
      </div>
    )
  }
}
