import gql from 'graphql-tag'

const SUBSCRIPTION_YOURSTATS = gql`
  subscription($id: Int!) {
    character(where: {id: {_eq: $id}}) {
      id
      name
      auth_level
      signup_date
      level
      character_details {
        strength
        endurance
        agility
        diamonds
        emeralds
        crystals
        rubies
        keys
        topaz
        garnet
        amethyst
        tourmaline
        aquamarine
        opal
        sapphire
        clay
        stone
        wood
        experience
        experience_needed
        health
        max_health
        mana
        max_mana
        gold
        bank
        helm
        armour
        weapon
        kills
        deaths
        logins
        fights
        allowed_fights
        alignment
        last_login
      }
      landBylocation {
        x
        y
        worldByworldId {
          name
        }
      }
      equipped(order_by: { type: asc }){
        id
        name
        minvalue
        maxvalue
        type
      }
    }
  }
`

const QUERY_HOSPITAL = gql`
  query character($id: Int!) {
    character(where: {id: {_eq: $id}}) {
      id
      health
      name
      gold
    }
  }
`

const QUERY_FOUNTAIN = gql`
  query character($id: Int!) {
    character(where: {id: {_eq: $id}}) {
      id
      mana
      name
      gold
    }
  }
`

const QUERY_NEWS_LIST = gql`
  query {
    news_updates(order_by: { id: desc}, limit: 20) {
      id
      text
      type
      created_at
      characterBycharacterId {
        id
        name
        auth_level
      }
    }
  }
`

const QUERY_NEWS_ITEM = gql`
  query {
    news_updates(order_by: { id: desc}, limit: 1) {
      id
      text
      type
      created_at
      characterBycharacterId {
        id
        name
        auth_level
      }
    }
  }
`

const MUTATION_HEAL = gql`
  mutation heal($heal: Int!) {
    heal(heal: $heal) {
      status
      message
    }
  }
`
const MUTATION_RESTORE_MANA = gql`
  mutation restore_mana($mana: Int!) {
    restore_mana(mana: $mana) {
      status
      message
    }
  }
`

const MUTATION_DEPOSIT_WITHDRAW = gql`
  mutation deposit_withdraw($amount: Int!) {
    deposit_withdraw(amount: $amount) {
      status
      message
    }
  }
`

const MUTATION_TRAIN = gql`
  mutation train_stat($stats: String!) {
    train_stat(stats: $stats) {
      status
      message
      payload
    }
  }
`

const QUERY_TRAINS = gql`
  query character($id: Int!) {
    character(where: { id: { _eq: $id}}) {
      id
      character_details {
        level
        strength
        endurance
        agility
        trains
      }
    }
  }
`

const MUTATION_SCRAP = gql`
  mutation($equip: Int!) {
    scrap(equip: $equip) {
      status
      message
      payload
    }
  }
`

const QUERY_BANK = gql`
  query character($id: Int!) {
    character(where: { id: { _eq: $id}}) {
      id
      character_details {
        bank
      }
    }
  }
`

const SUBSCRIPTION_BANK = gql`
  subscription character($id: Int!) {
    character(where: { id: { _eq: $id}}) {
      id
      character_details {
        bank
      }
    }
  }
`

const SUBSCRIPTION_INVENTORY = gql`
  subscription ($id: Int!) {
    character(where: {id: {_eq: $id}}) {
      character_details {
        helm
        armour
        weapon
      }
      character_item_inventory(order_by: { name: asc, state: asc }) {
        name
        count
        state
        item_id
        type
      }
      equipmentsBycharacterId(order_by: { type: asc, maxvalue: desc }) {
        id
        name
        type
        maxvalue
        minvalue
        base_efficiency
        enhancementsByequipmentId(order_by: {type: asc}) {
          id
          type
          value
        }
      }
    }
  }
`

const MUTATION_EQUIP_ITEM = gql`
  mutation($equip: Int!) {
    equip(equip: $equip) {
      status
      message
      payload
    }
  }
`

const SUBSCRIPTION_ONLINE = gql`
  subscription($last: timestamptz){
    character(
      where: { last_action: { _gte: $last } },
      order_by: { name: asc }
    ) {
      id
      name
      level
      auth_level
      last_action
    }
  }
`

export {
  MUTATION_DEPOSIT_WITHDRAW,
  MUTATION_HEAL,
  MUTATION_RESTORE_MANA,
  MUTATION_TRAIN,
  MUTATION_EQUIP_ITEM,
  MUTATION_SCRAP,
  QUERY_BANK,
  QUERY_FOUNTAIN,
  QUERY_HOSPITAL,
  QUERY_TRAINS,
  QUERY_NEWS_LIST,
  QUERY_NEWS_ITEM,
  SUBSCRIPTION_BANK,
  SUBSCRIPTION_INVENTORY,
  SUBSCRIPTION_YOURSTATS,
  SUBSCRIPTION_ONLINE
}