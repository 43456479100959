import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import { client } from '../routes'
import gql from 'graphql-tag';
import OutworldFightResult from '../Outworld/OutworldFightResult';

export default class Battle extends Component {
  constructor() {
    super()

    this.state = {
      button_text: 'Fight',
      fighting: false,
      message: 'Loading...',
      autos: 500,
      status_message: null,
      timer: 0
    }

    this.interval = null

    this.battleAction = this.battleAction.bind(this)
    this.fight = this.fight.bind(this)
    this.stop = this.stop.bind(this)
    this.tick = this.tick.bind(this)
    this.timer_tick = this.timer_tick.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
  }

  battleAction() {
    this.state.fighting ? this.stop() : this.fight()
  }

  fight() {
    this.interval = setInterval(this.tick, 6000)

    this.setState({ fighting: true, button_text: 'Stop' })
    this.tick()
  }

  async timer_tick() {
    let timer = this.state.timer - 1

    this.setState({ timer: timer <= 0 ? 0 : timer })
    
    if(this.state.timer > 0) {
      setTimeout(this.timer_tick, 1000)
    }
  }

  tick() {
    client.mutate({
      mutation: gql`
        mutation {
          battle {
            status
            message
            payload
          }
        }
      `
    }).then((response) => {
      if(response.data.battle.status === 200) {
        try {
          let payload = JSON.parse(response.data.battle.payload)

          this.setState({
            compass_message: '',
            status_message: (<OutworldFightResult hide_log={this.state.hide_log} log={payload.fight_result} />),
            location_id: 0
          })

          this.timer_tick()
        } catch (e) {
          console.log(e)
        }     
      } else {
        this.stop()
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.battle.message}</div>),
          compass_message: ''
        })
      }
    })

    let newAutos = this.state.autos - 1
    let message = newAutos > 0 ? `You have ${newAutos} fights remaining` : this.state.message
    this.setState({ autos: newAutos, message: message, timer: 6 })

    if(newAutos < 1) {
      this.stop()
    }
  }

  stop() {
    clearInterval(this.interval)
    this.setState({ autos: 500, message: 'You have stopped battling...', fighting: false, button_text: 'Fight'})
  }

  componentWillUnmount() {
    this.stop()
  }

  render() {
    return (
      <div className="battle-wrapper">
        <div className="battle-timer-action">
          <div className="battle-timer-wrapper">
            Timer: {this.state.timer}
          </div>
          <div className="battle-action-button-wrapper">
            <Button
              id="battleActionButton"
              bsSize="small"
              className="btn-margin game-button"
              onClick={() => { this.battleAction() }}
              >
              {this.state.button_text}
            </Button>
          </div>
        </div>
        <div>{this.state.message}</div>
        {this.state.status_message}
      </div>
    )
  }
}
