import React, { Component } from 'react'
import ItemEnhancements from './ItemEnhancements';

export default class InventoryItemList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      collapse_weapons: false,
      collapse_armour: false,
      collapse_helms: false
    }

    this.collapse_section = this.collapse_section.bind(this)
  }
  nameFromEquipmentType(type) {
    switch(type) {
      case 1:
        return "Weapons"
      case 2:
        return "Armour"
      case 3:
        return "Helms"
      default:
        return
    }
  }

  collapse_section(type) {
    let key = `collapse_${this.nameFromEquipmentType(type).toLowerCase()}`
    this.setState({ ...this.state, ...{ [key]: !this.state[key] }})
  }

  render() {
    let item_list
    let i = 0

    return (
      <div>
        {
          this.props.equipped && this.props.inventory.map((item) => {
            return (
              <div key={item.id}>
                <div>
                  {item.name} ({item.minvalue} - {item.maxvalue})
                  {
                    !this.props.hide_action && (
                      <span className="inventory-equipment-link" onClick={() => this.props.equip(item.id)}> [{this.props.equipped ? 'Remove' : 'Equip'}]</span>
                    )
                  }
                  <ItemEnhancements enhancements={item.enhancementsByequipmentId} base_efficiency={item.base_efficiency} />
                </div>
              </div>
            )
          })
        }
        {
          !this.props.equipped && this.props.inventory.map((list) => {
            let type = list[0].type
            return (
              <div key={type}>
                <button className="inventory-equipment-section-header long-button" onClick={() => this.collapse_section(type)}>
                  {this.nameFromEquipmentType(type)} ({list.length})
                </button>
                <div className={`inventory-equipment-section ${this.state[`collapse_${this.nameFromEquipmentType(type).toLowerCase()}`] ? 'collapsed-section' : ''}`}>
                  {
                    list.map((item) => {
                      return (
                        <div key={item.id}>
                          {item.name} ({item.minvalue} - {item.maxvalue})
                          {
                            !this.props.hide_action && (
                              <span className="inventory-equipment-link" onClick={() => this.props.equip(item.id)}> [{this.props.equipped ? 'Remove' : 'Equip'}]</span>
                            )
                          }
                          <ItemEnhancements enhancements={item.enhancementsByequipmentId} base_efficiency={item.base_efficiency} />
                          [<button className="link-button inventory-scrap-button" onClick={() => this.props.scrap(item.id)}>Scrap</button>]
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}
