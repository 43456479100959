import gql from 'graphql-tag'

let QUERY_CACHE_LAND_STATE = gql`
  query {
    current_land {
      id
      mobs_here
      owner_id
      land_extra_details {
        gold_to_collect
        wood
        clay
        stone
      }
      lands {
        title
        id
      }
      players {
        id
        name
        level
      }
      monsters {
        id
        monsterBymonsterId {
          name
          level
        }
      }
      barrels {
        id
        cost
      }
      worlds {
        id
        name
        minlevel
        lid
      }
      world_map {
        id
        lid
        x
        y
        pk
        portal
      }
      location_details {
        x
        y
        description
        tax
        resource_bonus
        tax_collected
        pk
        portal
        title
        name
        id
        loc
        owner
      }
      items {
        type
        state
        count
        item_id
        name
      }
    }
  }
`

let QUERY_CACHE_CHARACTERS_HERE = gql`
  query {
    current_land {
      players {
        id
        name
        level
      }
    }
  }
`

let QUERY_CACHE_LAND_PORTALS = gql`
  query {
    current_land {
      lands {
        id
        title
      }
    }
  }
`

let QUERY_CACHE_MOBS_HERE = gql`
  query {
    current_land {
      id
      mobs_here
      monsters {
        id
        monsterBymonsterId {
          name
          level
        }
      }
    }
  }
`

let QUERY_CACHE_LAND_INFORMATION = gql`
  query {
    current_land {
      id
      land_extra_details {
        gold_to_collect
        wood
        stone
        clay
      }
      owner_id
      location_details {
        tax
        resource_bonus
        tax_collected
        name
        owner
      }
    }
  }
`

let QUERY_CACHE_LAND_DESCRIPTION = gql`
  query {
    current_land {
      id
      location_details {
        x
        y
        description
        title
        name
      }
    }
  }
`

let QUERY_CACHE_LAND_BARRELS = gql`
  query {
    current_land {
      id
      barrels {
        id
        cost
      }
    }
  }
`

let QUERY_CACHE_WORLD_PORTALS = gql`
  query {
    current_land {
      id
      worlds {
        id
        name
        minlevel
        lid
      }
      location_details {
        portal
      }
    }
  }
`

let QUERY_CACHE_WORLD_MAP = gql`
  query {
    current_land {
      id
      world_map {
        id
        lid
        x
        y
        pk
        portal
      }
    }
  }
`

let QUERY_CACHE_LAND_OWNER = gql`
  query {
    current_land {
      owner_id
    }
  }
`
let QUERY_CACHE_ITEMS = gql`
  query {
    current_land {
      items {
        type
        state
        count
        item_id
        name
      }
    }
  }
`

export {
  QUERY_CACHE_LAND_STATE,
  QUERY_CACHE_MOBS_HERE,
  QUERY_CACHE_LAND_INFORMATION,
  QUERY_CACHE_LAND_DESCRIPTION,
  QUERY_CACHE_LAND_BARRELS,
  QUERY_CACHE_LAND_PORTALS,
  QUERY_CACHE_WORLD_PORTALS,
  QUERY_CACHE_WORLD_MAP,
  QUERY_CACHE_CHARACTERS_HERE,
  QUERY_CACHE_LAND_OWNER,
  QUERY_CACHE_ITEMS
}