import gql from 'graphql-tag'

const QUERY_WEAPONS = gql`
  query {
    weapon (order_by: { cost: asc }) {
      id
      name
      description
      min_damage
      max_damage
      cost
    }
  }
`

const QUERY_ARMOUR = gql`
  query {
    armour (order_by: { cost: asc }) {
      id
      name
      description
      min_defence
      max_defence
      cost
    }
  }
`

const QUERY_STAFF = gql`
  query {
    character (
      where: { auth_level: {_gt: 0 }},
      order_by: [{ auth_level: desc }, { id: asc }]
    ){
      id
      auth_level
      name
    }
  }
`

const QUERY_CRIMINALS = gql`
  query {
    account(where: { jailed: { _eq: true } }) {
      id
      username
    }
  }
`

const MUTATION_BUY_EQUIPMENT = gql`
  mutation($weapon: Int, $armour: Int) {
    purchase(weapon: $weapon, armour: $armour) {
      status
      message
      payload
    }
  }
`

const QUERY_GEM_SHOP_EQUIPPED = gql`
  query($id: Int!) {
    character(where: { id: { _eq: $id}}) {
      id
      character_details {
        bank
        diamonds
        emeralds
        crystals
        rubies
      }
      equipped(order_by: { type: asc }) {
        id
        name
        maxvalue
        minvalue
        base_efficiency
        full_enhancements
      }
    }
  }
`

const MUTATION_ENHANCE_ITEM = gql`
  mutation($id: Int!, $enhance: Int!) {
    enhance(equip: $id, enhance: $enhance) {
      status
      message
    }
  }
`

const MUTATION_ENCHANT_ITEM = gql`
  mutation($id: Int!, $type: Int!) {
    enchant(equip: $id, type: $type) {
      status
      message
    }
  }
`

const MUTATION_UPGRADE_ITEM = gql`
  mutation($id: Int!) {
    upgrade(equip: $id) {
      status
      message
    }
  }
`

export {
  QUERY_WEAPONS,
  QUERY_ARMOUR,
  QUERY_STAFF,
  QUERY_CRIMINALS,
  QUERY_GEM_SHOP_EQUIPPED,
  MUTATION_BUY_EQUIPMENT,
  MUTATION_ENHANCE_ITEM,
  MUTATION_ENCHANT_ITEM,
  MUTATION_UPGRADE_ITEM
}