import React, { Component } from 'react'

import { client } from '../routes'
import Back from '../Common/Back'
import { QUERY_GEM_SHOP_EQUIPPED, MUTATION_ENHANCE_ITEM, MUTATION_ENCHANT_ITEM, MUTATION_UPGRADE_ITEM } from './queries/villageQueries'
import { idFromJWT } from '../Utilities'
import { enhance_types } from '../Utilities/magicConstants'
import ItemEnhancements from '../Game/ItemEnhancements'

export default class GemShop extends Component {
  constructor() {
    super()

    this.state = {
      panel: 'home',
      equipped: [],
      bottom_panel: null,
      active_upgrade: null,
      hover_upgrade: null,
      hover_enchant: null,
      hover_enchant_picker: null,
      message: null,
      enhance_picker: null
    }

    this.show_panel = this.show_panel.bind(this)
    this.query_equipment = this.query_equipment.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.enhance = this.enhance.bind(this)
    this.enchant = this.enchant.bind(this)
    this.upgrade = this.upgrade.bind(this)
    this.get_text = this.get_text.bind(this)
    this.validate_cost = this.validate_cost.bind(this)
    this.choose_enhance = this.choose_enhance.bind(this)
  }

  choose_enhance(id, enhancements) {
    this.setState({ 
      enhance_picker: true,
      hover_enchant: null
    })
  }

  show_panel(panel) {
    this.setState({ show: panel })
  }

  enhance(item, enh, value) {
    if(this.state.panel !== 'enhance') {
      return
    }

    let cost = this.enhance_cost(value)

    if(!this.validate_cost(cost)) {
      return
    }

    client.mutate({
      mutation: MUTATION_ENHANCE_ITEM,
      variables: { enhance: enh, id: item }
    }).then((response) => {
      let enhanced = response.data.enhance
      if(enhanced.status === 200) {
        this.setState({
          message: (<div className="status-message-success">{enhanced.message}</div>) 
        })
      } else {
        this.setState({
          message: (<div className="status-message-fail">{enhanced.message}</div>)
        })
      }

      this.query_equipment()
    }).catch((e) => {
      console.log(e)
    })
  }

  enchant(id, num, type) {
    if(this.state.panel !== 'enchant') {
      return
    }

    let cost = this.enchant_cost(num)
    if(!this.validate_cost(cost)) {
      return
    }

    client.mutate({
      mutation: MUTATION_ENCHANT_ITEM,
      variables: { id: id, type: type }
    }).then((response) => {
      let enchanted = response.data.enchant
      if(enchanted.status === 200) {
        this.setState({
          enhance_picker: null,
          message: (<div className="status-message-success">{enchanted.message}</div>) 
        })
      } else {
        this.setState({
          message: (<div className="status-message-fail">{enchanted.message}</div>)
        })
      }

      this.query_equipment()
    }).catch((e) => {
      console.log(e)
    })
  }

  upgrade(id, efficiency) {
    if(this.state.panel !== 'upgrade') {
      return
    }

    let cost = this.efficiency_cost(efficiency)
    if(!this.validate_cost(cost)) {
      return
    }

    client.mutate({
      mutation: MUTATION_UPGRADE_ITEM,
      variables: { id: id }
    }).then((response) => {
      let upgraded = response.data.upgrade
      if(upgraded.status === 200) {
        this.setState({
          message: (<div className="status-message-success">{upgraded.message}</div>) 
        })
      } else {
        this.setState({
          message: (<div className="status-message-fail">{upgraded.message}</div>)
        })
      }

      this.query_equipment()
    }).catch((e) => {
      console.log(e)
    })
  }

  validate_cost(cost) {
    return this.state.character.bank >= cost.gold &&
      this.state.character.crystals >= cost.crystals &&
      this.state.character.diamonds >= cost.diamonds &&
      this.state.character.emeralds >= cost.emeralds
  }

  enhance_cost = (level) => {
    level++
    let modifier = Math.ceil(Math.pow(level, 2) + Math.floor(level * 0.25))
    return {
      diamonds: 11 * modifier,
      emeralds: 8 * modifier,
      crystals: 121 * modifier,
      gold: 25000 * modifier
    }
  }

  efficiency_cost = (level) => {
    let modifier = Math.pow(level, level)
    return {
      diamonds: Math.ceil(5 * modifier),
      emeralds: Math.ceil(3 * modifier),
      crystals: Math.ceil(21 * modifier),
      rubies: Math.ceil(1 * modifier),
      gold: Math.ceil(25000 * modifier)
    }
  }
  
  enchant_cost = (level) => {
    let modifier = Math.ceil(Math.pow(level + 1, 3))
    return {
      diamonds: 12 * modifier,
      emeralds: 8 * modifier,
      crystals: 45 * modifier,
      rubies: 1 * modifier,
      gold: 75000 * modifier
    }
  }

  async query_equipment() {
    client.query({
      query: QUERY_GEM_SHOP_EQUIPPED,
      variables: { id: idFromJWT()},
      fetchPolicy: 'network-only'
    }).then((response) => {
      let equipped = response.data.character[0].equipped
      let character = response.data.character[0].character_details
      if(equipped) {
        this.setState({
          equipped: equipped,
          character: character
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  componentDidMount() {
    this.query_equipment()
  }

  get_text(type, value) {
    let cost
    switch(type) {
      case 'enhance':
        cost = this.enhance_cost(value)
        break;
      case 'enchant':
        cost = this.enchant_cost(value)
        break
      case 'upgrade':
        cost = this.efficiency_cost(value)
        break;
      default:
        return
    }

    return (
      <div>
        <div className={`${this.state.character.bank >= cost.gold ? 'gem-shop-can-afford' : 'gem-shop-not-afford'}`}>Gold: {cost.gold.toLocaleString()}</div>
        <div className={`${this.state.character.crystals >= cost.crystals ? 'gem-shop-can-afford' : 'gem-shop-not-afford'}`}>Crystals: {cost.crystals.toLocaleString()}</div>
        <div className={`${this.state.character.diamonds >= cost.diamonds ? 'gem-shop-can-afford' : 'gem-shop-not-afford'}`}>Diamonds: {cost.diamonds.toLocaleString()}</div>
        <div className={`${this.state.character.emeralds >= cost.emeralds ? 'gem-shop-can-afford' : 'gem-shop-not-afford'}`}>Emeralds: {cost.emeralds.toLocaleString()}</div>
        {
          cost.rubies > 0 && <div className={`${this.state.character.rubies >= cost.rubies ? 'gem-shop-can-afford' : 'gem-shop-not-afford'}`}>Rubies: {cost.rubies.toLocaleString()}</div>
        }
      </div>
    )
  }

  render() {
    return (
      <div className="gem-shop-wrapper">
        <div>
          <div className="gem-shop-text">
            Welcome to the Gem shop. What would you like to upgrade?
          </div>
          <div className="gem-shop-message">{this.state.message}</div>
          <div className="gem-shop-equipment">
            {
              this.state.equipped.map((item) => {
                let enhancements = []
                item.full_enhancements.map((i) => { if(i) { enhancements.push(i) } return null })
                return (
                  <div className="gem-shop-item-wrapper" key={item.id}>
                    <span className="gem-shop-item" onClick={() => this.setState({ selected_item: item.id, panel: 'home', bottom_panel: null, enhance_picker: null, message: null })}>{item.name} | {item.minvalue} - {item.maxvalue}</span> |
                    <div className="gem-shop-base-efficiency-wrapper">
                      <span className={`gem-shop-base-efficiency ${this.state.active_upgrade === item.id && this.state.panel === 'upgrade'  ? `clickable gem-shop-${this.validate_cost(this.efficiency_cost(item.base_efficiency)) ? 'can' :'not'}-afford` : ''}`}
                        onMouseLeave={() => this.setState({ hover_upgrade: null })} 
                        onMouseEnter={() => this.setState({ hover_upgrade: item.id })}
                        onClick={() => this.upgrade(item.id, item.base_efficiency)}
                      >&nbsp;{item.base_efficiency}</span>
                      {
                        this.state.hover_upgrade === this.state.active_upgrade && this.state.hover_upgrade === item.id && this.state.shop_panel && this.state.panel === 'upgrade' && (
                          <div className="upgrade-efficiency-panel">
                            {this.state.shop_panel.text('upgrade', item.base_efficiency)}
                          </div>
                        )
                      }
                    </div>
                    <ItemEnhancements enhancements={enhancements} base_efficiency={item.base_efficiency} item={item.id} bottom_panel={this.state.bottom_panel} on_click={this.enhance} />
                    {
                      this.state.enhance_picker && this.state.active_upgrade === item.id && <div className="gem-shop-enhance-picker">
                        <div className="gem-shop-enhance-picker-wrapper">
                          {
                            enhance_types().map((enh) => {
                              return (
                                !enhancements.find((e) => e.type === enh.type) && (
                                  <div 
                                  className="gem-shop-enhance-picker-item" key={enh.type}
                                  onMouseLeave={() => this.setState({ hover_enchant_picker: null })}
                                  onMouseEnter={() => this.setState({ hover_enchant_picker: enh.type })}
                                  onClick={() => this.enchant(item.id, enhancements.length, enh.type)}
                                  >
                                    {enh.text}|
                                    {
                                      this.state.hover_enchant_picker === enh.type && this.state.shop_panel && this.state.panel === 'enchant' && (
                                        <div className="enchant-panel">
                                          {this.state.shop_panel.text('enchant', enhancements.length)}
                                        </div>
                                      )
                                    }
                                  </div>
                                )
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      this.state.panel === 'enchant' && this.state.active_upgrade === item.id && (
                        <div className="gem-shop-enchant-wrapper">
                          {
                            Math.floor(item.base_efficiency) + 1 > item.full_enhancements.length && !this.state.enhance_picker && (
                              <div className="inline-wrapper">
                                { enhancements.length === 0 && <span>&nbsp;|</span>}
                                <span className="gem-shop-enchant-link" 
                                  onMouseLeave={() => this.setState({ hover_enchant: null })} 
                                  onMouseEnter={() => this.setState({ hover_enchant: item.id })}
                                  onClick={() => this.choose_enhance(item.id, enhancements)}
                                >&nbsp;+&nbsp;</span>
                                {
                                  this.state.hover_enchant === item.id && this.state.shop_panel && this.state.panel === 'enchant' && (
                                    <div className="enchant-panel">
                                      {this.state.shop_panel.text('enchant', enhancements.length)}
                                    </div>
                                  )
                                }
                              |</div>
                            )
                          }
                          {
                            Math.floor(item.base_efficiency) + 1 <= item.full_enhancements.length && (
                              <div className="inline-wrapper">
                                <span className="gem-shop-enchant-no-link">&nbsp;x&nbsp;</span>
                              |</div>
                            )
                          }
                          {
                            this.state.enhance_picker && (
                              <div className="cancel-wrapper">
                                <span className="gem-shop-cancel-link" onClick={() => this.setState({ enhance_picker: null })}>cancel</span>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                    {
                      this.state.selected_item === item.id && (
                        <div>
                          <div className="gem-shop-actions">
                            <span className={`gem-shop-link ${this.state.panel === 'enhance' ? 'gem-shop-link-active' : ''}`} onClick={() => this.setState({ enhance_picker: null, message: null, panel: 'enhance', active_upgrade: null, bottom_panel: { text: this.get_text, item: item.id }, shop_panel: null })}> [Enhance]</span> 
                            <span className={`gem-shop-link ${this.state.panel === 'enchant' ? 'gem-shop-link-active' : ''}`} onClick={() => this.setState({ enhance_picker: null, message: null, panel: 'enchant', active_upgrade: item.id, bottom_panel: null, shop_panel: { text: this.get_text, item: item.id} })}> [Enchant]</span> 
                            <span className={`gem-shop-link ${this.state.panel === 'upgrade' ? 'gem-shop-link-active' : ''}`} onClick={() => this.setState({ enhance_picker: null, message: null, panel: 'upgrade', active_upgrade: item.id, bottom_panel: null, shop_panel: { text: this.get_text, item: item.id} })}> [Upgrade]</span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
              })
            }
          </div>

          <Back />
        </div>
      </div>
    )
  }
}
