import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import dotenv from 'dotenv'
import { makeMainRoutes } from './routes';

dotenv.config()
const routes = makeMainRoutes();
ReactDOM.render(
  routes,
  document.getElementById('root')
);
