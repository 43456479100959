import React from 'react'

export const formatNumber = (number) => {
  return parseInt(number).toLocaleString()
}

export const formatNumberDecimal = (number) => {
  return number.toFixed(1)
}

export const formatLife = (life, max) => {
  let percent = life / max
  let className = "life-safe"

  if (percent <= 0.2) className = "life-critical"
  else if (percent <= 0.35) className = "life-danger"
  else if (percent <= 0.5) className = "life-warn"

  return (
    <div className="health">
      <span className={className}>{formatNumber(life)}/{formatNumber(max)}</span>
    </div>
  )
}

export const formatExperience = (exp, exp_needed) => {
  return (
    <span className="user-experience">
      {formatNumber(exp)}/{formatNumber(exp_needed)}
    </span>
  )
}

export const formatLevel = (level) => {
  return (
    <span className="user-level">
      {formatNumber(level)}
    </span>
  )
}

export const formatAlign = (align) => {
  let className = align >= 0 ? "align-positive" : "align-negative"

  return (
    <span className={className}>
      {formatNumberDecimal(align)}
    </span>
  )
}
