import React, { Component } from 'react'

import { Subscription } from 'react-apollo'
import { SUBSCRIPTION_YOURSTATS } from './queries/gameQueries';

import { idFromJWT } from '../Utilities/sessionVariables'
import { formatAlign, formatExperience, formatLife, formatNumber, formatName, castFormatDate, castDaysSince } from '../Utilities'
import LatestNewsItem from './LatestNewsItem';

export default class YourStats extends Component {
  render() {
    let types = ['Weapon', 'Armour', 'Helm']
    return (
      <div className="stats-wrapper">
        <Subscription subscription={SUBSCRIPTION_YOURSTATS} variables={{ id: idFromJWT() }}>
          {({ loading, error, data }) => {
            if (loading) {
              return null
            }
            if (error) {
              console.log(error)
              return null
            }

            let character = data.character[0]
            return (
              <div>
                <div>
                  Character Image
                </div>
                <br />
                <div>
                  <LatestNewsItem />
                </div>
                <br />
                <div className="stats-page-wrapper">
                  <div className="stats-left-column">
                    <div className="underlined bolded stats-stats-header">Stats:</div>
                    <div className="stats-stat-item">
                      <span className="bolded">Name:</span>
                      <span className="stats-stat">{formatName(character.name, character.auth_level)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Game ID:</span>
                      <span className="stats-stat">{formatNumber(character.id)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Level:</span>
                      <span className="stats-stat">{formatNumber(character.level)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Experience:</span>
                      <span className="stats-stat">{formatExperience(character.character_details.experience, character.character_details.experience_needed)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Life:</span>
                      <span className="stats-stat">{formatLife(character.character_details.health, character.character_details.max_health)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Alignment:</span>
                      <span className="stats-stat">{formatAlign(character.character_details.alignment)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Mana:</span>
                      <span className="stats-stat">{formatNumber(character.character_details.mana)}/{formatNumber(character.character_details.max_mana)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Days Old:</span>
                      <span className="stats-stat">{castDaysSince(character.signup_date)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Total Logins:</span>
                      <span className="stats-stat">{formatNumber(character.character_details.logins)}</span>
                    </div>
                    <div className="stats-stat-item">
                      <span className="bolded">Last Login:</span>
                      <span className="stats-stat">{castFormatDate(character.character_details.last_login)}</span>
                    </div>
                    <div className="stats-battle">
                      <div className="underlined bolded stats-character-stats-header">Character Stats:</div>
                      <div className="stats-stat-item">
                        <span className="bolded">Strength:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.strength)}</span>
                      </div>
                      <div className="stats-stat-item">
                        <span className="bolded">Agility:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.agility)}</span>
                      </div>
                      <div className="stats-stat-item">
                        <span className="bolded">Endurance:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.endurance)}</span>
                      </div>
                      <div className="stats-stat-item">
                        <span className="bolded">Kills:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.kills)}</span>
                      </div>
                      <div className="stats-stat-item">
                        <span className="bolded">Deaths:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.deaths)}</span>
                      </div>
                      <div className="stats-stat-item">
                        <span className="bolded">Fights:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.fights)}/{formatNumber(character.character_details.allowed_fights)}</span>
                      </div>
                    </div>
                    <div className="stats-house">
                      <div className="underlined bolded stats-house-header">House:</div>
                      <div className="stats-other-item">
                        <span className="bolded">House Name:</span>
                        <span className="stats-stat">McLovins</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Level:</span>
                        <span className="stats-stat">{formatNumber(25)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Members:</span>
                        <span className="stats-stat">2/3</span>
                      </div>
                    </div>
                    <div className="stats-resources">
                      <div className="underlined bolded stats-house-header">Resources:</div>
                      <div className="stats-other-item">
                        <span className="bolded">Clay:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.clay)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Stone:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.stone)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Wood:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.wood)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="stats-right-column">
                    <div className="stats-equipment">
                      <div className="underlined bolded stats-equipment-header">Equipment:</div>
                        <div className="stats-equipment-wrapper">
                          {
                            character.equipped && character.equipped.map((item) => {
                              return (
                                <div key={item.id} className="stats-equipment-item">
                                  <span className="stats-equipment-type">{types[item.type-1]}</span>: <span className="stats-equipment-name-value">{item.name} ({item.minvalue} - {item.maxvalue})</span>
                                </div>
                              )
                            })
                          }
                        </div>
                    </div>
                    <div className="stats-other-items">
                      <div className="underlined bolded stats-other-items-header">Other Items:</div>
                      <div className="stats-other-item">
                        <span className="bolded">Crystals:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.crystals)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Emeralds:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.emeralds)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Diamonds:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.diamonds)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Rubies:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.rubies)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Keys:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.keys)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Topaz:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.topaz)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Garnet:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.garnet)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Amethyst:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.amethyst)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Tourmaline:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.tourmaline)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Aquamarine:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.aquamarine)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Opal:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.opal)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Sapphire:</span>
                        <span className="stats-stat">{formatNumber(character.character_details.sapphire)}</span>
                      </div>
                    </div>
                    <div className="stats-clan">
                      <div className="underlined bolded stats-clan-header">Clan:</div>
                      <div className="stats-other-item">
                        <span className="bolded">Clan Name:</span>
                        <span className="stats-stat">Super Dudes</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Level:</span>
                        <span className="stats-stat">{formatNumber(12)}</span>
                      </div>
                      <div className="stats-other-item">
                        <span className="bolded">Houses:</span>
                        <span className="stats-stat">3/4</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        </Subscription>
      </div>
    )
  }
}
