import React, { Component } from 'react'

import { randomNumber } from '../Utilities'

import { client } from '../routes'
import { QUERY_CACHE_MOBS_HERE } from './queries/outworldCacheQueries'

export default class OutworldMonsters extends Component {
  constructor(props) {
    super(props)
    this.mob = React.createRef();

    this.state = {
      monsters: [],
      mobs_here: 0,
      random_mob: null,
      observer: null,
      show_filter_set: false,
      min_mob: props.min_mob,
      max_mob: props.max_mob
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  get_mob() {
    return this.mob.current ? parseInt(this.mob.current.innerText) : null
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_MOBS_HERE
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              mobs_here: data.current_land.mobs_here,
              monsters: data.current_land.monsters
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  render() {
    let half = Math.ceil(this.state.monsters.length / 2)
    let left = this.state.monsters.slice(0, half)
    let right = this.state.monsters.slice(half, this.state.monsters.length)

    let format_mob = (monster) => {
      return (
        <div key={monster.id}>
          <button className="no-select link-button outworld-button outworld-fight-monster" onClick={() => { this.props.fight(monster.id)}}>
            {monster.monsterBymonsterId.name} (level {monster.monsterBymonsterId.level})
          </button>
        </div>
      )
    }

    return (
      <div>
        <div>
          <div className="outworld-mob-wrapper">
            <div className="outworld-mobs-here">There are {this.state.mobs_here} monsters here</div>
            <div className="outworld-mob-filter-control">              
              <button className="link-button outworld-button" onClick={() => this.setState({ show_filter_set: !this.state.show_filter_set })}>
                {this.props.min_mob}-{this.props.max_mob}
              </button>
              {
                this.state.show_filter_set && (
                  <div>
                    <input pattern="[0-9]*" className="outworld-mob-filter-input" value={this.state.min_mob} onChange={(e) => { if(e.target.validity.valid) { this.setState({ min_mob: e.target.value})}} }/>
                    <input pattern="[0-9]*" className="outworld-mob-filter-input" value={this.state.max_mob} onChange={(e) => { if(e.target.validity.valid) { this.setState({ max_mob: e.target.value})}} }/>
                    <button className="outworld-mob-filter-save-button" onClick={() => { this.props.set_filter(this.state.min_mob, this.state.max_mob); this.setState({ show_filter_set: false })}}>&#x2714;</button>
                  </div>
                )
              }
            </div>
          </div>
          <div className="outworld-monster-list">
            <div className="mob-left">
              {
                left.map((monster) => {
                  return format_mob(monster)
                })
              }
            </div>
            <div className="mob-right">
              {
                right.map((monster) => {
                  return format_mob(monster)
                })
              }
            </div>
          </div>
          {
            this.state.monsters.length > 0 && (
              <div>
                <div style={{ display: 'none' }} ref={this.mob}>{this.state.monsters[randomNumber(0, this.state.monsters.length - 1)].id}</div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
