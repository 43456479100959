import React, { Component } from 'react'

export default class OutworldCompass extends Component {
  render() {
    return (
      <div className="outworld-compass-wrapper">
        <div className="no-select outworld-compass-link outworld-compass-north">
          <button className="link-button outworld-compass-button" onClick={() => { this.props.move(1)}}>North</button>
        </div>
        <div className="no-select outworld-compass-link outworld-compass-horizontal">
          <div className="outworld-compass-west">
          <button className="link-button outworld-compass-button" onClick={() => { this.props.move(2)}}>West</button>
          </div>
          <div className="no-select outworld-compass-link outworld-compass-east">
          <button className="link-button outworld-compass-button" onClick={() => { this.props.move(3)}}>East</button>
          </div>
        </div>
        <div className="no-select outworld-compass-link outworld-compass-south">
          <button className="link-button outworld-compass-button" onClick={() => { this.props.move(4)}}>South</button>
        </div>
        <div className="outworld-compass-message-wrapper">
          {this.props.message}
        </div>
      </div>
    )
  }
}
