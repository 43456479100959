import React, { Component } from 'react'

import YourStats from './YourStats'
import Account from './Account'

export default class Profile extends Component {
  constructor() {
    super()

    this.state = {
      panel: 'stats'
    }
  }

  render() {
    return (
      <div>
        <div className="profile-tabs">
          <div onClick={() => this.setState({ panel: 'stats' })} className={`profile-tab ${this.state.panel === 'stats' ? 'active-tab' : ''}`}>Stats</div>
          <div onClick={() => this.setState({ panel: 'account' })} className={`profile-tab ${this.state.panel === 'account' ? 'active-tab' : ''}`}>Account</div>
        </div>
        {
          this.state.panel === 'stats' && (
            <YourStats />
          )
        }
        {
          this.state.panel === 'account' && (
            <Account />
          )
        }
      </div>
    )
  }
}
