import React, { Component } from 'react'

import { client } from '../routes'
import { QUERY_CACHE_LAND_PORTALS } from './queries/outworldCacheQueries'

export default class OutworldLandPortals extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show_portals: false,
      lands: []
    }

    this.show_hide_portals = this.show_hide_portals.bind(this)
    this.teleport_to_land = this.teleport_to_land.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.init_watch = this.init_watch.bind(this)
  }

  init_watch() {
    try {
      let observer = client.watchQuery({
        query: QUERY_CACHE_LAND_PORTALS
      })
      .subscribe(async ({ data, loading }) => {
        if(data && data.current_land) {
          if(!loading) {
            await this.setState({
              lands: data.current_land.lands
            })
          }
        }
      })

      this.setState({ observer: observer })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    setTimeout(() => this.init_watch(), 50)
  }

  componentWillUnmount() {
    if(this.state.observer) {
      this.state.observer.unsubscribe()
    }
  }

  teleport_to_land(to_loc) {
    this.props.teleport(to_loc)
  }

  show_hide_portals() {
    this.setState({ show_portals: !this.state.show_portals})
  }

  render() {
    return (
      <div className="outworld-land-portals-wrapper">
        <div className="outworld-land-portals">
          <div className="outworld-land-portals-header" onClick={this.show_hide_portals}>Teleporter</div>
          <div className="outworld-land-portal-links">
            {
              this.state.show_portals && this.state.lands.map((land) => {
                return (
                  <div className="outworld-land-teleporter-link" onClick={() => this.teleport_to_land(land.id)} key={land.id}>
                    {
                      `${land.title}`
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}
