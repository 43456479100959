import React, { Component } from 'react'
import fetch from 'isomorphic-fetch'

import gql from 'graphql-tag'
import { client } from '../routes'

// import history from '../history'

import './Login.css'
import { AUTH_SERVICE_URL } from '../constants';

export default class Login extends Component {
  constructor() {
    super()

    this.state = {
      message: null
    }

    this.displayMessages = this.displayMessages.bind(this)
    this.setSession = this.setSession.bind(this)
    this.loginClick = this.loginClick.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
  }

  displayMessages(message) {
    console.log(message)
    this.setState({ message: message })
  }

  setSession(token) {
    let expiresAt = JSON.stringify((86400 * 1000) + new Date().getTime()) //Expire in 24 hours
    localStorage.setItem('auth:access_token', token)
    localStorage.setItem('auth:expires_at', expiresAt)
    // history.replace('/game')

    //NOTE: Super hack to fix authentication issue with token not being present at link connection
    window.location = '/'
  }

  loginClick(e) {
    e.preventDefault()

    try {
      fetch(`${AUTH_SERVICE_URL}/login`, {
        method: "POST",
        mode: "cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ username: this.state.username, password: this.state.password })
      })
      .then((response) => {
        if (response.status >= 400) {
          response.json().then((res) => {
            this.displayMessages(res.message)  
          })

          return  
        }

        return response.json()
      })
      .then((returnedData) => {
        if(returnedData.status === 200) {
          let payload = JSON.parse(returnedData.payload)
          if(payload.token) {
            this.setSession(payload.token)
          }
          return
        } else {
          this.displayMessages(returnedData.message)
        }
      })
  
      //TODO: Uncomment when hasura remote schema proxy jwt bypass is implemented
      // client.mutate({
      //   mutation: gql`
      //     mutation {
      //       login(username: "${this.state.username}", password: "${this.state.password}") {
      //         status
      //         message
      //         payload
      //       }
      //     }
      //   `
      // }).then((response) => {
      //   if(response.data.login.status === 200) {
      //     let payload = JSON.parse(response.data.login.payload)
      //     if(payload.token) {
      //       this.setSession(payload.token)
      //     }
      //     return
      //   } else {
      //     this.displayMessages(response.data.login.message)
      //   }
      // }).catch((e) => {
      //   console.log(e)
      // })
    } catch(err) {
      console.log(err)
    }
  }

  handleUsernameChange(e) {
    this.setState({ username: e.target.value })
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value })
  }

  forgotPassword() {
    console.log('TODO: Implement forgotPassword')
  }

  render() {
    return (
      <div className="login-component-wrapper">
        <div className="login-message">
          {this.state.message}
        </div>
        <form className="login-form" onSubmit={this.loginClick}>
          <div className="login-container">
            <label>Username</label>
            <input autoFocus type="text" placeholder="Enter Username" name="username" onChange={this.handleUsernameChange} required />

            <label>Password</label>
            <input type="password" placeholder="Enter Password" name="password" onChange={this.handlePasswordChange} required />
              
            <button type="submit">Login</button>
          </div>
          <div className="login-container login-footer">
            <button type="button" className="cancelbtn" onClick={this.props.cancel}>Cancel</button>
            <span className="psw"><a onClick={this.forgotPassword}>Forgot password?</a></span>
          </div>
        </form>
      </div>
    )
  }
}
