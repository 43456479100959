import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import { client } from '../routes'
import { MUTATION_RESET_ME, MUTATION_GENERATE_REFERRAL, QUERY_ACCOUNT_REFERRALS } from './queries/settingsQueries'
import { accountFromJWT } from '../Utilities'


export default class Account extends Component {
  constructor() {
    super()
    this.state = {
      show_reset_me: false,
      show_referrals: false,
      message: '',
      active_code: null,
      total_referrals: 0,
      account: null,
      referrals: []
    }

    this.reset_me_click = this.reset_me_click.bind(this)
    this.generate_code = this.generate_code.bind(this)
    this.fetch_referrals = this.fetch_referrals.bind(this)
    this.show_hide_referrals = this.show_hide_referrals.bind(this)
  }

  async fetch_referrals() {
    try {
      let data = await client.query({
        query: QUERY_ACCOUNT_REFERRALS,
        variables: { id: accountFromJWT() },
        fetchPolicy: 'network-only'
      })

      let account
      if(data.data && data.data.account) {
        //TODO: This will need to be updated once multiple characters become a thing
        account = data.data.account[0]

        let total_referrals = account && account.accountsByreferrer.length
        let active_code = account && account.referralsByaccountId.length > 0 
          ? (
            <div className="account-active-referral-code">| Active Code: {account.referralsByaccountId[0].code}</div>
          )
          : null

        this.setState({ total_referrals: total_referrals, active_code: active_code, referrals: account.accountsByreferrer })
      }
    } catch(e) {
      console.log(e)
    }
  }

  async generate_code() {
    client.mutate({
      mutation: MUTATION_GENERATE_REFERRAL
    }).then((data) => {
      this.fetch_referrals()
      this.setState({ message: data.data.generate_referral.message, message_status: data.data.generate_referral.status })
    }).catch((e) => {
      console.log(e)
    })
  }

  async reset_me_click() {
    this.setState({ panel: null })

    client.mutate({
      mutation: MUTATION_RESET_ME
    }).then((data) => {
      this.setState({ message: data.data.reset_me.message, message_status: data.data.generate_referral.status })
    }).catch((e) => {
      console.log(e)
    })
  }

  show_hide_referrals() {
    if(this.state.panel !== "referral") {
      this.fetch_referrals()
    }

    this.setState({ panel: this.state.panel === "referral" ? null : "referral", message: '' })
  }

  render() {
    return (
      <div>
        <div className={`account-display-message ${this.state.message_status === 200 ? 'account-success-message' : 'account-error-message'}`}>{this.state.message}</div>
        <div className={`account-reset-me clickable no-select ${this.state.panel === "resetme" ? 'selected' : ''}`} onClick={() => this.setState({ panel: this.state.panel === "resetme" ? null : "resetme", message: '' })}>Reset Me</div>
        <div className={`account-referrals clickable no-select ${this.state.panel === "referral" ? 'selected' : ''}`} onClick={this.show_hide_referrals}>Referrals</div>
        {
          this.state.panel === "resetme" && (
            <div>
              <div className="account-reset-me-warning">Are you sure? This can not be undone.</div>
              <Button
                bsSize="small"
                className="btn-margin game-button"
                onClick={this.reset_me_click}
              >
              Reset Me
              </Button>
            </div>
          )
        }
        {
          this.state.panel === "referral" && (
            <div>
              <div className="account-referrals-content">
                <div className="account-referrals-generate">
                  <Button
                    bsSize="small"
                    className="btn-margin game-button"
                    onClick={this.generate_code}
                  >
                  Generate Code
                  </Button>
                </div>
                <div className="account-referrals-list">
                  <div> 
                    <div className="referral-list-wrapper">
                      Referrals: {this.state.total_referrals} {this.state.active_code}
                      <div className="referral-list">
                        {
                          this.state.referrals && this.state.referrals.map((ref) => {
                            return (
                              <div key={ref.id}>
                                Name: {ref.charactersByaccountId[0].name}
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
