import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import './Header.css'

export default class Header extends Component {
  render() {
    return (
      <div>
        <div className="header-links">
        Help Files Submit Ticket
        </div>
        <div className="login-wrapper">
          <Button
            id="qsLogoutBtn"
            bsStyle="primary"
            className="btn-margin"
            onClick={this.props.logout}
          >
            Log Out
          </Button>
        </div>
      </div>
    )
  }
}
