import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import Back from '../Common/Back'
import { MUTATION_TRAIN, QUERY_TRAINS } from './queries/gameQueries'

import { idFromJWT } from '../Utilities/sessionVariables'
import { client } from '../routes'

export default class Workout extends Component {
  constructor() {
    super()
    this.state = {
      message: null,
      trains: 0,
      agility: 0,
      strength: 0,
      endurance: 0,
      committed: 0,
      max: 0,
      stats: {
        strength: 0,
        agility: 0,
        endurance: 0
      }
    }

    this.refresh_state = this.refresh_state.bind(this)
    this.trainStat = this.trainStat.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.total_stats = this.total_stats.bind(this)
  }

  async refresh_state() {
    client.query({
      query: QUERY_TRAINS,
      variables: { id: idFromJWT()},
      fetchPolicy: 'network-only'
    }).then((response) => {
      let character = response.data.character[0].character_details
      if(character) {
        this.setState({
          trains: character.trains,
          strength: character.strength,
          agility: character.agility,
          endurance: character.endurance,
          max: character.trains
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  async trainStat() {
    try {
      let stats = {
        strength: this.state.stats.strength,
        agility: this.state.stats.agility,
        endurance: this.state.stats.endurance
      }

      if(this.state.committed > this.state.max) {
        this.setState({ message: (<div className="status-message-fail">You do not have that many trains</div>)})
        return
      }

      let escaped_stats = JSON.stringify(JSON.stringify(stats))
      client.mutate({
        mutation: MUTATION_TRAIN,
        variables: { stats: escaped_stats }
      }).then((response) => {
        if(response.data.train_stat.status === 200) {
          let payload = JSON.parse(response.data.train_stat.payload)
          console.log(payload)
          this.setState({
            trains: this.state.trains + payload.trains,
            strength: this.state.strength + payload.strength,
            agility: this.state.agility + payload.agility,
            endurance: this.state.endurance + payload.endurance,
            message: (<div className="status-message-success">{response.data.train_stat.message}</div>)
          })
        } else {
          this.setState({
            message: (<div className="status-message-fail">{response.data.train_stat.message}</div>)
          })
        }
      })
    } catch(e) {
      console.log(e)
    }
  }

  componentDidMount() {
    this.refresh_state()
  }

  total_stats() {
    return parseInt(this.state.stats.strength) + parseInt(this.state.stats.endurance) + parseInt(this.state.stats.agility)
  }

  render() {
    return (
      <div>
        <h5>Workout Center</h5>
        <div className="workout-wrapper">
          <div>
            Welcome to the workout center. You have {this.state.trains} trains remaining
          </div>
          <div className="workout-stats-wrapper">
            <div className="workout-stat">
              Strength: {this.state.strength.toLocaleString()}
            </div>
            <div className="workout-stat">
              Agility: {this.state.agility.toLocaleString()}
            </div>
            <div className="workout-stat">
              Endurance: {this.state.endurance.toLocaleString()}
            </div>
          </div>
          <div className="parentContainer">
            <div>
              <div className="status-message-container">
                {this.state.message}
              </div>
              <div className="workout-center-buttons">
                STR: <input type="text" value={this.state.stats.strength} onChange={(i) => this.setState({ stats: { ...this.state.stats, strength: i.target.value }})} className="workout-stat-input" /> <br/>
                AGI: <input type="text" value={this.state.stats.agility} onChange={(i) => this.setState({ stats: { ...this.state.stats, agility: i.target.value }})} className="workout-stat-input" /> <br/>
                END: <input type="text" value={this.state.stats.endurance} onChange={(i) => this.setState({ stats: { ...this.state.stats, endurance: i.target.value }})} className="workout-stat-input" /> <br/>
                <br />
                <div className={`workout-total-stats ${this.total_stats() > this.state.trains ? 'workout-total-stats-error' : ''}`}>
                  {this.total_stats()}/{this.state.trains}
                </div>
                <Button
                  id="restoreButton"
                  bsSize="small"
                  className="btn-margin game-button"
                  onClick={() => { this.trainStat() }}
                >
                  Train Stats
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Back />
      </div>
    )
  }
}
