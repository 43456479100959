import React, { Component } from 'react'
import { Route } from "react-router-dom";

import Chat from '../Chat/Chat'
import Navigation from '../Navigation/Navigation'

import Account from './Account'
import Bank from './Bank'
import Fountain from './Fountain';
import Hospital from './Hospital'
import Inventory from './Inventory';
import Members from './Members'
import Mail from './Mail'
import News from './News'
import Online from './Online'
import Profile from './Profile'
import Workout from './Workout'

import Admin from '../Admin/Admin'
import Village from '../Village/Village'
import Outworld from '../Outworld/Outworld'

import './Game.css'

export default class Game extends Component {
  render() {
    return !this.props.auth.isAuthenticated() ? null : (
      <div className="game-wrapper">
        <main className="game-main">
          <Route exact path={this.props.match.path} component={Profile} />
          <Route path={`${this.props.match.url}/inventory`} component={Inventory} />
          <Route path={`${this.props.match.url}/heal`} component={Hospital} />
          <Route path={`${this.props.match.url}/fountain`} component={Fountain} />
          <Route path={`${this.props.match.url}/outworld`} component={Outworld} />
          <Route path={`${this.props.match.url}/village`} component={Village} />
          <Route path={`${this.props.match.url}/bank`} component={Bank} />
          <Route path={`${this.props.match.url}/workout`} component={Workout} />
          <Route path={`${this.props.match.url}/members`} component={Members} />
          <Route path={`${this.props.match.url}/mail`} component={Mail} />
          <Route path={`${this.props.match.url}/online`} component={Online} />
          <Route path={`${this.props.match.url}/news`} component={News} />
          <Route path={`${this.props.match.url}/admin`} component={Admin} />
          <Route path={`${this.props.match.url}/account`} component={Account} />
        </main>
        <aside className="game-aside game-nav">
          <Navigation />
        </aside>
        <aside className="game-aside game-chat">
          <Chat />
        </aside>
        <footer className="game-footer">
        </footer>
      </div>
    )
  }
  
  componentDidMount() {
    if (!this.props.auth.isAuthenticated()) {
      this.props.history.push('/')
    }
  }
}
