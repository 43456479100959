import React, { Component } from 'react'

import { client } from '../routes'
import { QUERY_WEAPON_LIST, MUTATION_UPDATE_WEAPON, MUTATION_INSERT_WEAPON } from './queries/adminQueries.js'

export default class AdminWeapons extends Component {
  constructor() {
    super()

    this.state = {
      weapons: [],
      edit_weapon: { name: '', description: '', cost: '', max_damage: '', min_damage: '', efficiency: ''},
      default_edit_weapon: { name: '', description: '', cost: '', max_damage: '', min_damage: '', efficiency: '' },
      add_weapon: {},
      message: '',
      message_state: 'success'
    }

    this.query_weapons = this.query_weapons.bind(this)
    this.insert_weapon = this.insert_weapon.bind(this)
    this.save_weapon = this.save_weapon.bind(this)
    this.save_weapon_click = this.save_weapon_click.bind(this)
    this.display_message = this.display_message.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  display_message(message, message_state) {
    this.setState({
      message: message,
      message_state: message_state === 1 ? 'success' : 'error'
    })
  }

  save_weapon_click(e) {
    e.preventDefault()
    if(this.state.edit_weapon.id) {
      this.save_weapon()
    } else {
      this.insert_weapon()
    }
  }

  async query_weapons() {
    client.query({
      query: QUERY_WEAPON_LIST,
      fetchPolicy: 'network-only'
    }).then((response) => {
      let weapons = response.data.weapon
      if(weapons) {
        this.setState({
          weapons: weapons
        })
      }
    }).catch((e) => {
      console.log(e.networkError)
    })
  }

  async insert_weapon() {
    if(!this.state.add_weapon) {
      this.setState({ message: "No weapon selected to edit"})
      return
    }

    client.mutate({
      mutation: MUTATION_INSERT_WEAPON,
      variables: { obj: [this.state.edit_weapon] }
    }).then(() => {
      this.query_weapons()
    }).catch((e) => {
      console.log(e)
    })
  }

  async save_weapon() {
    if(!this.state.edit_weapon.id) {
      this.setState({ message: "No weapon selected to edit"})
      return
    }
    let { id, ...set } = this.state.edit_weapon

    client.mutate({
      mutation: MUTATION_UPDATE_WEAPON,
      variables: { id: id, set: set }
    }).then(() => {
      this.query_weapons()
    }).catch((e) => {
      console.log(e)
    })
  }

  componentDidMount() {
    this.query_weapons()
  }

  render() {
    return (
      <div>
        <div className={`admin-display-${this.state.message_state}-message`}>{this.state.message}</div>
        <div className="admin-edit-weapon">
          <div className="admin-edit-weapon-button-wrapper">
            {
              this.state.weapons && this.state.weapons.length > 0 && (
                <form className="edit-weapon-form" onSubmit={this.save_weapon_click}>
                  <table className="admin-edit-weapon-table">
                    <thead>
                      <tr>
                        <th onClick={() => this.setState({ edit_weapon: this.state.default_edit_weapon})}>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Cost</th>
                        <th>Min Dmg</th>
                        <th>Max Dmg</th>
                        <th>Efficiency</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td>
                            {this.state.edit_weapon.id}
                          </td>
                          <td>
                            <input autoFocus className="input-weapon-text-input" type="text" value={this.state.edit_weapon.name} placeholder="Name" name="aw_name" onChange={(e) => this.setState({ edit_weapon: { ...this.state.edit_weapon, name: e.target.value }})} required />
                          </td>
                          <td>
                            <input className="input-weapon-text-input" type="text" value={this.state.edit_weapon.description} placeholder="Description" description="aw_description" onChange={(e) => this.setState({ edit_weapon: { ...this.state.edit_weapon, description: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_weapon.cost} placeholder="Cost" name="aw_cost" onChange={(e) => this.setState({ edit_weapon: { ...this.state.edit_weapon, cost: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_weapon.min_damage} placeholder="Minimum Damage" name="aw_min_damage" onChange={(e) => this.setState({ edit_weapon: { ...this.state.edit_weapon, min_damage: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_weapon.max_damage} placeholder="Maximum Damage" name="aw_max_damage" onChange={(e) => this.setState({ edit_weapon: { ...this.state.edit_weapon, max_damage: e.target.value }})} required />
                          </td>
                          <td>
                            <input pattern="[0-9]*" className="number-input" value={this.state.edit_weapon.efficiency} placeholder="Efficiency" name="aw_efficiency" onChange={(e) => this.setState({ edit_weapon: { ...this.state.edit_weapon, efficiency: e.target.value }})} required />
                          </td>
                          <td>
                            <button className="admin-edit-weapon-submit game-button" type="submit">Save</button>
                          </td>
                      </tr>
                      {
                        this.state.weapons.map((weapon) => {
                          return (
                            <tr key={weapon.id}>
                              <td>{weapon.id}</td>
                              <td>{weapon.name}</td>
                              <td>{weapon.description}</td>
                              <td>{weapon.cost}</td>
                              <td>{weapon.min_damage}</td>
                              <td>{weapon.max_damage}</td>
                              <td>{weapon.efficiency}</td>
                              <td>
                                <button className="game-button" type="button" onClick={() => this.setState({ edit_weapon: weapon })}>Edit</button>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </form>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
