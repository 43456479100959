import React, { Component } from 'react'

export default class Members extends Component {
  render() {
    return (
      <div>
        Members Page
      </div>
    )
  }
}
