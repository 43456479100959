import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class VillageNavigation extends Component {
  render() {
    return (
      <div className="village-navigation">
        <div className="village-column">
          <div className="village-section">
            <div className="village-section-header">Palace:</div>
            <div><Link to="/game/village/staff">Staff</Link></div>
            <div>Voting</div>
            <div><Link to="/game/village/rankings">Rankings</Link></div>
            <div>Raffle Rankings</div>
            <div>Help Files</div>
          </div>

          <div className="village-section">
            <div className="village-section-header">Residential:</div>
            <div>zipster&#39;s Stats</div>
            <div>Messageboard</div>
            <div><Link to="/game/mail">Post Office</Link></div>
            <div>Raffle Tickets</div>
            <div>Battle Layout</div>
          </div>
        </div>
        <div className="village-column">
          <div className="village-section">
            <div className="village-section-header">Market Place:</div>  
            <div><Link to="/game/village/gemshop">Gem Shop</Link></div>
            <div><Link to="/game/village/weapon">Weapons</Link></div>
            <div><Link to="/game/village/armour">Armour</Link></div>
            <div><Link to="/game/village/market">Market</Link></div>
          </div>

          <div className="village-section">
            <div className="village-section-header">Downtown:</div>
            <div>Clans</div>
            <div><Link to="/game/bank">Bank</Link></div>
            <div><Link to="/game/heal">Hospital</Link></div>
            <div><Link to="/game/fountain">Fountain</Link></div>
          </div>
          
          <div className="village-section">
            <div className="village-section-header">Outside Village:</div>  
            <div><Link to="/game/village/jail">Jail</Link></div>
            <div><Link to="/game/outworld">Leave Village</Link></div>
          </div>
        </div>
      </div>
    )
  }
}
