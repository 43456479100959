import React, { Component } from 'react'

export default class Market extends Component {
  render() {
    return (
      <div>
        Market Component
      </div>
    )
  }
}
