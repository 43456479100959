import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'

import { QUERY_ARMOUR, MUTATION_BUY_EQUIPMENT } from './queries/villageQueries'
import Back from '../Common/Back';

export default class Armour extends Component {
  constructor() {
    super()

    this.buy = this.buy.bind(this)
  }

  buy(buy_armour, id) {
    let vars = {
      variables: {
        armour: id
      }
    }

    buy_armour(vars)
  }

  render() {
    return (
      <div className="armour-shop-wrapper">
        <Query query={gql`${QUERY_ARMOUR}`}>
          {({ loading, error, data }) => {
            if (loading) return <p>Loading...</p>;
            if (error) {
              return <p>Error: Your shit broke</p>;
            }
            let armour = data.armour
            return (
              <div>
                <Mutation mutation={MUTATION_BUY_EQUIPMENT}>
                  {(buy_armour, { data, loading, called, error }) => {
                    let message = null
                    if(error) {
                      console.log(error);
                                  
                      console.log("Error: IEEID1")
                    } else {
                      if(data && data.purchase) {                          
                        let result = data.purchase.message
                        let status = data.purchase.status

                        switch (status) {
                          case 500:
                            message = (
                              <span className="status-message-fail">
                                {result}
                              </span>
                            )
                            break
                          case 200:
                            message = (
                              <span className="status-message-success">
                                {result}
                              </span>
                            )

                            break
                          default:
                            message = null
                        }
                      }
                    }

                    return (
                      <div className="armour-shop-wrapper">
                        <div className="armour-shop-header">
                          Welcome to the armour shop
                        </div>
                        <div className="armour-shop-message">
                          {message} 
                        </div>
                        <div>
                          {
                            armour.length > 0 && armour.map((armour) => {
                              return (
                                <div key={armour.id}>
                                  {armour.name} | {armour.min_defence}-{armour.max_defence} | {armour.cost} | 
                                  <span className="inventory-equipment-link" onClick={() => this.buy(buy_armour, armour.id)}>[Buy]</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  }}
                </Mutation>
              </div>
            )
          }}
        </Query>
        <div>
          <Back />
        </div>
      </div>
    )
  }
}
