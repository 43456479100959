import React, { Component } from 'react'
import { currentDate } from '../Utilities';

export default class UserClock extends Component {
  constructor() {
    super()

    this.state = {
      time: this.currentTime()
    }
  }

  currentTime() {
    return currentDate()
  }

  render() {
    return (
      <div className="user-clock-wrapper">
        <div><span className="bolded">Time:</span> <span className="user-info-time">{this.state.time}</span></div>
      </div>
    )
  }

  timeIntverval = setInterval( () => {
    this.setState({
      time : this.currentTime()
    })
  },1000)

  componentWillUnmount() {
    if(this.timeIntverval) {
      clearInterval(this.timeIntverval)
    }
  }
}
