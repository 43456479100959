import React, { Component } from 'react'
import { enhance_type } from '../Utilities';

export default class ItemEnhancements extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active_tooltip: null
    }
  }
  render() {
    return (
      <div className="item-enhancement-wrapper">
        {
          this.props.enhancements.length > 0 && (
            <div className="inventory-item-enhancements">
              |{
                this.props.enhancements.map((enh) => {
                  return (
                    <div key={enh.id} className="inventory-enhancement-line">
                      {
                        this.state.active_tooltip === enh.id && (
                          <div className="ud-tooltip enhancement-tooltip">
                            {enhance_type(enh.type)}
                          </div>
                        )
                      }
                      {
                        this.props.bottom_panel && this.props.bottom_panel.item === this.props.item && this.state.active_tooltip === enh.id && (
                          <div className="enhancement-action-panel">
                            {this.props.bottom_panel.text('enhance', enh.value)}
                          </div>
                        )
                      }
                      <span 
                        className={`inventory-enhancement-value inventory-enhancement-value-${enh.type} ${this.props.on_click && this.props.bottom_panel && this.props.item === this.props.bottom_panel.item ? 'clickable' : '' }`} 
                        onMouseLeave={() => this.setState({ active_tooltip: null })} 
                        onMouseEnter={() => this.setState({ active_tooltip: enh.id })}
                        onClick={() => this.props.on_click ? this.props.on_click(this.props.item, enh.id, enh.value) : null}
                      >
                        {enh.value * this.props.base_efficiency}%
                      </span>|
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </div>
    )
  }
}
