import React, { Component } from 'react'
import { Subscription } from 'react-apollo'
import { SUBSCRIPTION_INVENTORY, MUTATION_EQUIP_ITEM, MUTATION_SCRAP } from './queries/gameQueries'
import { idFromJWT } from '../Utilities'
import InventoryItemList from './InventoryItemList'
import InventoryItems from './InventoryItems'

import { client } from '../routes'

export default class Inventory extends Component {
  constructor() {
    super()

    this.state = {
      show_equipment: true,
      show_items: false
    }

    this.try_scrap = this.try_scrap.bind(this)
    this.scrap = this.scrap.bind(this)
    this.equip = this.equip.bind(this)
  }

  groupBy(list, keyGetter) {
    const map = []
    if(list) {
      list.forEach((item) => {
          const key = keyGetter(item)
          const collection = map[key]
          if (!collection) {
            map[key] = [item]
          } else {
            collection.push(item)
          }
      })
    }
    return map
  }

  equip(id) {
    client.mutate({
      mutation: MUTATION_EQUIP_ITEM,
      variables: { equip: id }
    }).then((response) => {
      if(response.data.equip.status === 200) {
        this.setState({
          status_message: (<div className={`status-message-success`}>{response.data.equip.message}</div>)
        })
      } else {
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.equip.message}</div>)
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  try_scrap(item) {
    if (window.confirm('Are you sure you wish to scrap this item?')) {
      this.scrap(item)
    }
  }

  scrap(item) {
    client.mutate({
      mutation: MUTATION_SCRAP,
      variables: { equip: item }
    }).then((response) => {
      if(response.data.scrap.status === 200) {
        this.setState({
          status_message: (<div className={`status-message-success`}>{response.data.scrap.message}</div>)
        })
      } else {
        this.setState({
          status_message: (<div className="status-message-fail">{response.data.scrap.message}</div>)
        })
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  render() {
    return (
      <div>
        <Subscription subscription={SUBSCRIPTION_INVENTORY} variables={{ id: idFromJWT()}}>
          {({loading, error, data}) => {
            if(error){
              console.log(error)
              return null
            }

            let inventory = null, equipped = null, items = null

            if(data && data.character && data.character.length > 0) {
              let character = data.character[0]
              inventory = character.equipmentsBycharacterId.filter((item) => {
                return !Object.values(character.character_details).includes(item.id)
              })

              equipped = character.equipmentsBycharacterId.filter((item) => {
                return Object.values(character.character_details).includes(item.id)
              })

              items = character.character_item_inventory
            }

            if(inventory && inventory.length > 0) {
              inventory = this.groupBy(inventory, i => i.type)
            }

            let inventory_items = inventory && <InventoryItemList inventory={inventory} equip={this.equip} scrap={this.try_scrap} />
            let equipped_items =  equipped && <InventoryItemList inventory={equipped} equipped={true} equip={this.equip} />
            let item_inventory = items && <InventoryItems inventory={items} />

            return (
              <div>

                <div className="inventory-wrapper">
                  <div className="inventory-tabs">
                    <div onClick={() => this.setState({ show_equipment: true, show_items: false })} className={`inventory-tab ${this.state.show_equipment ? 'active-tab' : ''}`}>Equipment</div>
                    <div onClick={() => this.setState({ show_equipment: false, show_items: true })} className={`inventory-tab ${this.state.show_items ? 'active-tab' : ''}`}>Items</div>
                  </div>
                  <div className="inventory-message">
                    {this.state.status_message}
                  </div>
                  {
                    this.state.show_equipment && (
                      <div className="inventory-equipment-wrapper">
                        <div>
                          <div className="inventory-equipment-header">Equipped:</div>
                          {equipped_items}
                        </div>
                        <div>
                          <div className="inventory-equipment-header">Other Equipment:</div>
                          {inventory_items}
                        </div>
                      </div>
                    )
                  }
                  {
                    this.state.show_items && (
                      <div className="inventory-item-wrapper">
                        {item_inventory}
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }} 
        </Subscription>
      </div>
    )
  }
}
