import gql from 'graphql-tag'

const SUBSCRIPTION_YOUR_INFO = gql`
  subscription($character_id: Int) {
    character(where: {id: {_eq: $character_id}}, limit: 1) {
      id
      character_details {
        name
        level
        auth_level
        mana
        max_mana
        experience
        experience_needed
        health
        max_health
        gold
        bank
        fights
        allowed_fights
        alignment
      }
    }
  }
`

const SUBSCRIPTION_PLAYERS_ONLINE = gql`
  subscription {
    players_online {
      count
    }
  }
`

export {
  SUBSCRIPTION_YOUR_INFO,
  SUBSCRIPTION_PLAYERS_ONLINE
}