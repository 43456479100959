import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

import { QUERY_STAFF } from './queries/villageQueries'
import { formatName } from '../Utilities/textFormatters'

export default class Staff extends Component {
  render() {
    return (
      <div>
        <Query query={gql`${QUERY_STAFF}`}>
          {({ loading, error, data }) => {
                if (loading) {
                  return <p>Loading...</p>;
                }

                if (error) {
                  return <p>Error: Your shit broke</p>;
                }

                //TODO: Figure out display issue with grouping by
                return (
                  <div>
                    <div>
                      {
                        data.character.length > 0 && data.character.map((staff) => {
                          return (
                            <div key={staff.id}>
                              {formatName(staff.name, staff.auth_level)}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
            }}
        </Query>
      </div>
    )
  }
}
