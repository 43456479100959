// import history from '../history';

export default class Auth {

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  login() {
    //TODO: Implement authorization logic
    console.log('TODO: Implement login')
  }

  handleAuthentication() {
    console.log('TODO: Implement handleAuthentication')
    //TODO: Implement handleAuthentication logic
    // this.auth0.parseHash((err, authResult) => {
    //   if (authResult && authResult.accessToken && authResult.idToken) {
    //     this.setSession(authResult);
    //     history.replace('/home');
    //   } else if (err) {
    //     history.replace('/home');
    //     console.error(err);
    //     alert(`Error: ${err.error}. Check the console for further details.`);
    //   }
    // });
  }

  logout() {
    localStorage.removeItem('auth:access_token');
    localStorage.removeItem('auth:expires_at');
    window.location = '/'
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('auth:expires_at'));
    return new Date().getTime() < expiresAt;
  }
}
