import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'
import './App.css'

import LoginModal from './LoginModal/LoginModal'
import LandingPage from './LandingPage/LandingPage'
import Header from './Header/Header'

class App extends Component {
  constructor() {
    super()

    this.logout = this.logout.bind(this)
  }

  goTo(route) {
    this.props.history.replace(`/${route}`)
  }

  login() {
    this.props.auth.login()
  }

  logout() {
    this.props.auth.logout()
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated()) {
      this.props.history.push('/game')
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth

    return (
      <div>
        {/* TODO: Make an Action Bar (navigation, refresh actions, etc) */}
        {
          !isAuthenticated() && (
            <div>
              <Navbar fluid>
                <Navbar.Header>
                  <Navbar.Brand>
                    <span>UDom</span>
                  </Navbar.Brand>
                  {
                    !isAuthenticated() && (
                        <LoginModal />
                      )
                  }
                  {
                    isAuthenticated() && (
                      <Header logout={this.logout} /> 
                      )
                  }
                </Navbar.Header>
              </Navbar>
              <LandingPage />
            </div>
          )
        }
      </div>
    )
  }
}

export default App
